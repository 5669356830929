import Card from 'react-bootstrap/Card';
import CountUp from 'react-countup';
import '../../assets/css/style.css';
import Banner from '../../components/Banner';
import Flow from '../../components/Flow';
import Footer from '../../components/Footer';
import HomeCarousel from '../../components/HomeCarousel';
import NavBar from '../../components/NavBar';

export default function HamannCorp() {
  return (
    <div className='App'>
      <NavBar />
      <div className='cont-mb-80'>
        <HomeCarousel />
        <Banner txt='Nós temos o melhor financiamento imobiliário para realizar o seu sonho' />
        <div className='d-flex flex-row flex-wrap justify-content-around my-5 cont-1300'>
          <Card
            border='light'
            style={{ width: '15rem' }}
          >
            <Card.Body className='text-center'>
              <CountUp
                duration={5}
                separator='.'
                end={2186}
                className='fc-counter'
              />
              <Card.Title className='text-center fc-blue f09'>
                Clientes Felizes
              </Card.Title>
            </Card.Body>
          </Card>

          <Card
            border='light'
            style={{ width: '15rem' }}
          >
            <Card.Body className='text-center'>
              <CountUp
                duration={5}
                separator='.'
                end={3421}
                className='fc-counter'
              />
              <Card.Title className='text-center fc-blue f09 '>
                Imóveis Financiados
              </Card.Title>
            </Card.Body>
          </Card>

          <Card
            border='light'
            style={{ width: '15rem' }}
          >
            <Card.Body className='text-center'>
              <CountUp
                duration={5}
                separator='.'
                end={14}
                className='fc-counter'
              />
              <Card.Title className='text-center fc-blue f09 '>
                Cidades Atendidas
              </Card.Title>
            </Card.Body>
          </Card>

          <Card
            border='light'
            style={{ width: '15rem' }}
          >
            <Card.Body className='text-center'>
              <CountUp
                duration={5}
                separator='.'
                end={18}
                className='fc-counter'
              />

              <Card.Title className='text-center fc-blue f09 '>
                Anos de Experiência
              </Card.Title>
            </Card.Body>
          </Card>
        </div>
        <Banner
          txt='Temos um aplicativo exclusivo para você acompanhar tudo em tempo real, além de atendimento todos os dias da semana'
          sub='h4'
        />

        <Flow />
      </div>
      <Footer />
    </div>
  );
}
