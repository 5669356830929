// import React, { useState } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';
import financiar from '../assets/js/financiar';
import { curr } from '../assets/js/utils';
import { faixas, variacoesCEF as variacoes } from '../config/indices';
import { evaluateFaixas, taxaPoupanca } from '../services/financ';

export default function Telegram() {
  const { renda } = useParams();
  const parcelaMaxima = 0.3 * renda;
  const nSac = 420;
  const nPrice = 360;
  // const [loading, setLoading] = useState(true);

  const calcularValorImovel = (tabela, faixaName) => {
    let valor_imovel,
      entradaPrice,
      entradaSac,
      financiado,
      parcelaPrice,
      parcelaSac;
    let taxa = faixas[faixaName].taxa;

    const variacao = faixas[faixaName]['variacao'];
    if (variacao) {
      if (variacao === 'poup') {
        taxa = taxaPoupanca(parseFloat(variacoes['tr'].value), variacoes);
      } else {
        taxa = variacoes[variacao]['value'] + faixas[faixaName]['taxa'];
      }
    }

    let finSac = new financiar(0, taxa, nSac, 0.02, 25, 'anual', nSac, nPrice);
    let finPrice = new financiar(
      0,
      taxa,
      nPrice,
      0.02,
      25,
      'anual',
      nSac,
      nPrice,
    );

    if (tabela === 'SAC') {
      finSac.financiarSacImovel(parcelaMaxima);
      valor_imovel = finSac.imovel;
      financiado = finSac.financiado;
      entradaSac = valor_imovel - financiado;
      parcelaSac = finSac.prestacao[0];
      return {
        valor_imovel,
        entradaPrice,
        entradaSac,
        taxa,
        financiado,
        parcelaPrice,
        parcelaSac,
      };
    } else if (tabela === 'PRICE') {
      finPrice.financiarPriceImovel(parcelaMaxima);
      valor_imovel = finPrice.imovel;
      financiado = finPrice.financiado;
      entradaPrice = valor_imovel - financiado;
      parcelaPrice = finPrice.prestacaoPrice;
      return {
        valor_imovel,
        entradaPrice,
        entradaSac,
        taxa,
        financiado,
        parcelaPrice,
        parcelaSac,
      };
    }
  };

  const imovel_valores = Object.keys(faixas).map((faixa) => {
    const valor_imovel_sac = calcularValorImovel('SAC', faixa);
    const valor_imovel_price = calcularValorImovel('PRICE', faixa);
    const tx = parseFloat(valor_imovel_sac.taxa).toFixed(2);

    return {
      faixaKey: faixa,
      faixa: faixas[faixa].name,
      taxa: tx,
      meses: { nSac, nPrice },
      entradaSac: valor_imovel_sac.entradaSac,
      entradaPrice: valor_imovel_price.entradaPrice,
      valor_imovel_sac: valor_imovel_sac.valor_imovel,
      valor_imovel_price: valor_imovel_price.valor_imovel,
      parcelaMaxima,
      parcelaPrice: valor_imovel_price.parcelaPrice,
      parcelaSac: valor_imovel_sac.parcelaSac,
    };
  });

  const ev = (imovel, faixa) => {
    const evaluate = evaluateFaixas(renda, imovel, faixas);
    const evaluateFaixa = evaluate[faixa];
    return evaluateFaixa;
  };

  // const analysis = () => {
  //   const res = imovel_valores.map((faixa) => {
  //     const evSac = ev(faixa.valor_imovel_sac, faixa.faixaKey);
  //     const evPrice = ev(faixa.valor_imovel_price, faixa.faixaKey);
  //     return {
  //       faixa: faixa.faixa,
  //       evSac,
  //       evPrice,
  //     };
  //   });
  //   const filteredRenda = res.filter(
  //     (faixa) => faixa.evSac.renda || faixa.evPrice.renda,
  //   );
  //   const filteredImovel = res.filter(
  //     (faixa) => faixa.evSac.imovel || faixa.evPrice.imovel,
  //   );
  //   // return { filteredRenda, filteredImovel};
  // };

  const result = imovel_valores.map((faixa) => {
    const evSac = ev(faixa.valor_imovel_sac, faixa.faixaKey);
    const evPrice = ev(faixa.valor_imovel_price, faixa.faixaKey);

    return {
      ...faixa,
      evSac,
      evPrice,
    };
  });

  return (
    <>
      <header className=''>
        <p className=' p-2 text-center softxt '>Esse é um bot</p>
      </header>
      <main className='d-flex flex-column justify-content-center'>
        <p className='d-flex flex-row justify-content-center'>
          <span className='mx-2'>Renda informada: {curr(renda)}</span>
          <span className='mx-2'>Parcela Máxima: {curr(parcelaMaxima)}</span>
        </p>

        <>
          <div className='d-flex flex-row f08 my-2 fc-regular fw600 justify-content-center'>
            <span className='mx-2 wp-100'>faixa</span>
            {/* <span className='mx-2 wp-100'>evaluation</span> */}
            <span className='mx-2 wp-100'>taxa</span>
            <span className='mx-2 wp-150'>parcela sac</span>
            <span className='mx-2 wp-150'>entrada sac</span>
            <span className='mx-2 wp-150'>imovel sac</span>
            <span className='mx-2 wp-150'>parcela price</span>
            <span className='mx-2 wp-150'>entrada price</span>

            <span className='mx-2 wp-150'>imovel price</span>
          </div>

          {result.map((faixa) => (
            <div
              key={faixa.faixa}
              className='d-flex flex-row f08 my-2 fc-regular justify-content-center'
            >
              <span className='mx-2 wp-100'>{faixa.faixa}</span>
              {/* <span className='mx-2 wp-100'>eval</span> */}
              <span className='mx-2 wp-100'>{faixa.taxa} %</span>

              {faixa.evSac && faixa.evSac.imovel ? (
                <>
                  <span className='mx-2 wp-150'>{curr(faixa.parcelaSac)}</span>
                  <span className='mx-2 wp-150'>{curr(faixa.entradaSac)}</span>
                  <span className='mx-2 wp-150'>
                    {curr(faixa.valor_imovel_sac)}
                  </span>
                </>
              ) : (
                <>
                  <span className='mx-2 wp-150'>-</span>
                  <span className='mx-2 wp-150'>-</span>
                  <span className='mx-2 wp-150'>-</span>
                </>
              )}

              {faixa.evPrice && faixa.evPrice.imovel ? (
                <>
                  <span className='mx-2 wp-150'>
                    {curr(faixa.parcelaPrice)}
                  </span>
                  <span className='mx-2 wp-150'>
                    {curr(faixa.entradaPrice)}
                  </span>
                  <span className='mx-2 wp-150'>
                    {curr(faixa.valor_imovel_price)}
                  </span>
                </>
              ) : (
                <>
                  <span className='mx-2 wp-150'>-</span>
                  <span className='mx-2 wp-150'>-</span>
                  <span className='mx-2 wp-150'>-</span>
                </>
              )}
            </div>
          ))}
        </>
      </main>
    </>
  );
}
