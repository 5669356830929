import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apoio, auth } from '../services/endpoints';
import * as api from '../services/requests';
import GlobalContext from './GlobalContext';

function GlobalProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [showSection, setShowSection] = useState('news');
  const [a, setA] = useState([]);
  const navigate = useNavigate();

  function store() {
    return {
      token: sessionStorage.getItem('@App:token'),
      email: sessionStorage.getItem('@App:mail'),
      nome: sessionStorage.getItem('@App:user'),
      codigo: sessionStorage.getItem('@App:codigo'),
      role: sessionStorage.getItem('@App:role'),
      apoio: JSON.parse(sessionStorage.getItem('@App:apoio')),
    };
  }

  useEffect(() => {
    const { apoio } = store();
    if (a.length === 0 && apoio !== null) {
      setup(apoio);
    }
  }, [a]);

  function checkLogin() {
    const { token, email, nome } = store();
    if (!token || !email || !nome) {
      return false;
    }
    return true;
  }

  async function login(userData) {
    const removeAspas = (str) => {
      return str.replace(/['"]+/g, '');
    };
    const response = await api.post(auth.login, userData);
    const { success } = response.data;

    if (success) {
      const { codigo, email, token, user, role } = response.data;
      sessionStorage.setItem('@App:token', removeAspas(JSON.stringify(token)));
      sessionStorage.setItem('@App:user', removeAspas(JSON.stringify(user)));
      sessionStorage.setItem('@App:mail', removeAspas(JSON.stringify(email)));
      sessionStorage.setItem('@App:role', removeAspas(JSON.stringify(role)));      
      sessionStorage.setItem(
        '@App:codigo',
        removeAspas(JSON.stringify(codigo)),
      );
      await getApoio(token);
    }
    return response;
  }

  async function userData() {
    const response = await api.post(auth.user, {});
    return response.data;
  }

  async function logout(full = 0) {
    const token = sessionStorage.getItem('@App:token');
    await api.post(auth.logout, {
      token,
      full,
    });
    sessionStorage.clear();
    localStorage.clear();
  }

  function setup(data) {
    const ap = {
      faixas: data.filter((info) => info.TABELA === 'faixas'),
      variacoes: data.filter((info) => info.TABELA === 'variacoes'),
      bancos: data.filter((info) => info.TABELA === 'bancos'),
      operacoes: data.filter((info) => info.TABELA === 'operacoes'),
      prioridades: data.filter((info) => info.TABELA === 'prioridades'),
      andamentos: data.filter((info) => info.TABELA === 'andamentos'),
      status: data.filter((info) => info.TABELA === 'status'),
      documentos: data.filter((info) => info.TABELA === 'documentos'),
      tabelas: data.filter((info) => info.TABELA === 'tabelas'),
      imobiliarias: data.filter((info) => info.TABELA === 'imobiliarias'),
      corretores: data.filter((info) => info.TABELA === 'corretores'),
      correspondentes: data.filter((info) => info.TABELA === 'correspondentes'),
      support_categories: data.filter(
        (info) => info.TABELA === 'support_categories',
      ),
    };
    setA(ap);
  }

  async function getApoio(token) {
    const response = await api.post(apoio, { token });
    const data = await response.data;

    setup(data);

    sessionStorage.setItem('@App:apoio', JSON.stringify(data));
  }

  const context = {
    loading,
    setLoading,
    api,
    checkLogin,
    getApoio,
    navigate,
    showSection,
    setShowSection,
    login,
    logout,
    a,
    store,
    userData,
  };

  return (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
  );
}

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalProvider;
