import PropTypes from 'prop-types';
import React from 'react';
import '../assets/css/app/formheader.css';

import conf from '../config/conf';

const FormHeader = ({ formname }) => (
  <div>
    <a href='/'>
      {' '}
      <img
        src={conf.images.logotext512}
        className='form-logo-header'
        alt='logo'
      />
    </a>
    <p className='mt-5 mb-3 form-login-title'>{formname}</p>
  </div>
);

FormHeader.propTypes = {
  formname: PropTypes.string.isRequired,
};

export default FormHeader;
