import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import '../assets/css/app/nav.css';
import help_icon from '../assets/img/feather/help-circle.svg';
import img_logout from '../assets/img/feather/log-out.svg';
import logo_menu from '../assets/img/feather/menu.svg';
import logo_user from '../assets/img/feather/user.svg';
import conf from '../config/conf';
import GlobalContext from '../context/GlobalContext';
import RenderSectionButton from './RenderSectionButton';
import AppButton from './buttons/AppButton';
import EmailButton from './buttons/EmailButton';
import InstagramButton from './buttons/InstagramButton';
import LoginButton from './buttons/LoginButton';
import SimuleButton from './buttons/SimuleButton';
import WhatsappButton from './buttons/WhatsappButton';

function NavBar() {
  const { checkLogin, store, setShowSection, navigate } =
    useContext(GlobalContext);

  const [logged, setLogged] = useState(false);
  const { nome } = store();

  useEffect(() => {
    const status = checkLogin();
    setLogged(status);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <nav className='d-flex flex-row justify-content-between w100 habita-nav'>
      <>
        <div className='nav-logo-container'>
          <img
            src={conf.images.logotext512}
            className='nav-logo logo-txt'
            alt={`${conf.tenant} logo`}
            onClick={() => {
              navigate('/');
              setShowSection('news');
            }}
          />
          <img
            src={conf.images.logo512}
            className='nav-logo logo-xs'
            alt={`${conf.tenant} logo`}
            onClick={() => {
              navigate('/');
              setShowSection('news');
            }}
          />
        </div>
      </>
      <div className='d-flex flex-row align-items-center'>
        <div className='d-flex flex-row align-items-center'>
          <ul className='d-flex flex-row justify-content-center align-items-center nav-ul'></ul>
        </div>
        <SimuleButton sm={true} />
        <InstagramButton />
        <WhatsappButton />
        <EmailButton />
        { logged ?  <AppButton /> : null }
        {logged ? (
          <div className='ms-3 nav-user'>
            <Dropdown>
              <Dropdown.Toggle
                variant=''
                className='d-flex flex-row align-items-center'
                id='dropdown-basic'
              >
                <img
                  src={logo_menu}
                  alt=''
                  className='show-xs imgsvg'
                />
                <span className='no_under mx-2 f08 softxt hide-xs'>{nome}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setShowSection('account')}>
                  <span className='sp-menu-icon'>
                    <img
                      src={logo_user}
                      alt='user'
                      className='imgsvg'
                    />
                  </span>
                  <span className='sp-menu-text'>Minha conta</span>
                </Dropdown.Item>
                <div className='gocol show-xs'>
                  <hr />
                  <Dropdown.Item>
                    <RenderSectionButton
                      section='simulacoes'
                      classn='habita-card-dropdown show-xs'
                      clk={() => setShowSection('simulacoes')}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <RenderSectionButton
                      section='financiamentos'
                      classn='habita-card-dropdown show-xs'
                      clk={() => setShowSection('financiamentos')}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <RenderSectionButton
                      section='tasks'
                      classn='habita-card-dropdown show-xs'
                      clk={() => setShowSection('tasks')}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <RenderSectionButton
                      section='processos'
                      classn='habita-card-dropdown show-xs'
                      clk={() => setShowSection('processos')}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <RenderSectionButton
                      section='clientes'
                      classn='habita-card-dropdown show-xs'
                      clk={() => setShowSection('clientes')}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <RenderSectionButton
                      section='admin'
                      classn='habita-card-dropdown show-xs'
                      clk={() => setShowSection('admin')}
                    />
                  </Dropdown.Item>

                  <hr />
                </div>

                <Dropdown.Item onClick={() => setShowSection('support')}>
                  <span className='sp-menu-icon'>
                    <img
                      src={help_icon}
                      alt='help'
                      className='imgsvg'
                    />
                  </span>
                  <span className='sp-menu-text'>Suporte</span>
                </Dropdown.Item>
                <Dropdown.Item href='/logout'>
                  <span className='sp-menu-icon'>
                    <img
                      src={img_logout}
                      alt='logout'
                      className='imgsvg'
                    />
                  </span>
                  <span className='sp-menu-text'>Sair</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <LoginButton />
        )}
      </div>
    </nav>
  );
}

export default NavBar;
