import Carousel from 'react-bootstrap/Carousel';
import img01 from '../assets/img/ia/img_ (1).jpg';
import img11 from '../assets/img/ia/img_ (11).jpg';
import img03 from '../assets/img/ia/img_ (3).jpg';
import img05 from '../assets/img/ia/img_ (5).jpg';
import img06 from '../assets/img/ia/img_ (6).jpg';
import img07 from '../assets/img/ia/img_ (7).jpg';
// import img02 from '../assets/img/ia/img_ (2).jpg';
// import img04 from '../assets/img/ia/img_ (4).jpg';
// import img08 from '../assets/img/ia/img_ (8).jpg';
// import img09 from '../assets/img/ia/img_ (9).jpg';
// import img10 from '../assets/img/ia/img_ (10).jpg';

import CarouselCaption from './CarouselCaption';
import CarouselImage from './CarouselImage';
import SimuleButton from './buttons/SimuleButton';

export default function HomeCarousel() {
  return (
    <div className='w-carousel my-4'>
      <Carousel fade>
        <Carousel.Item>
          <CarouselImage image={img01} />
          <CarouselCaption
            label='A sua casa nova a um clique de distância'
            sub={<SimuleButton />}
          />
        </Carousel.Item>
        <Carousel.Item>
          <CarouselImage image={img03} />
          <CarouselCaption
            label='Escolha onde VIVER'
            sub={<SimuleButton />}
          />
        </Carousel.Item>
        <Carousel.Item>
          <CarouselImage image={img06} />
          <CarouselCaption
            label='Ou mesmo financiar seu próprio projeto'
            sub={<SimuleButton />}
          />
        </Carousel.Item>
        <Carousel.Item>
          <CarouselImage image={img05} />
          <CarouselCaption
            label='Nós cuidaremos de tudo'
            sub={<SimuleButton />}
          />
        </Carousel.Item>

        <Carousel.Item>
          <CarouselImage image={img07} />
          <CarouselCaption
            label='Para que você tenha as melhores lembranças'
            sub={<SimuleButton />}
          />
        </Carousel.Item>

        <Carousel.Item>
          <CarouselImage image={img11} />
          <CarouselCaption
            label='Cuidamos de toda a documentação'
            sub={<SimuleButton />}
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
