import React, { useContext, useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaCircleCheck, FaLocationDot } from 'react-icons/fa6';
import { FcOpenedFolder } from 'react-icons/fc';
import { IoCheckmarkDoneOutline } from 'react-icons/io5';
import { MdExpand, MdOutlineUploadFile } from 'react-icons/md';
import { VscDebugStart } from 'react-icons/vsc';
import { curr } from '../../assets/js/utils';
import AndamentosLine from '../../components/AndamentosLine';
import EtapaIcon from '../../components/EtapaIcon';
import LoadingDots from '../../components/LoadingDots';
import DocumentosModal from '../../components/modals/DocumentosModal';
import GlobalContext from '../../context/GlobalContext';
import { proc } from '../../services/endpoints';
import { post } from '../../services/requests';
import Account from '../sections/Account';
import Support from '../sections/Support';

const Client = () => {
  const {
    checkLogin,
    roleCode,
    userCode,
    token,
    documentos,
    showSection,
  } = useContext(GlobalContext);

  // const { codigo } = useParams();
  const [processoCode, setProcessoCode] = useState('');
  const [processo, setProcesso] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [data, setData] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [arrayProcessos, setArrayProcessos] = useState([]);
  // const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [reload, setReload] = useState(false);
  const [documentosModal, setDocumentosModal] = useState([]);
  // const [mensagemModal, setMensagemModal] = useState([]);
  // const [tarefasModal, setTarefasModal] = useState([]);
  // const [agendamentosModal, setAgendamentosModal] = useState([]);
  // const [concluidosModal, setConcluidosModal] = useState([]);

  // const [logged, setLogged] = useState(false);

  // useEffect(() => {
  //   const status = checkLogin();
  //   setLogged(status);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {}, [showSection]);

  const handleToggleModal = (index, modal, prevStates) => {
    let newStates = [];
    switch (modal) {
      // case 'andamento':
      //   newStates = [...prevStates];
      //   newStates[index] = !newStates[index];
      //   setAndamentosModal(newStates);
      //   break;
      case 'documento':
        newStates = [...prevStates];
        newStates[index] = !newStates[index];
        setDocumentosModal(newStates);
        break;
      // case 'mensagem':
      //   newStates = [...prevStates];
      //   newStates[index] = !newStates[index];
      //   setMensagemModal(newStates);
      //   break;
      // case 'tarefa':
      //   newStates = [...prevStates];
      //   newStates[index] = !newStates[index];
      //   setTarefasModal(newStates);
      //   break;
      // case 'agendamento':
      //   newStates = [...prevStates];
      //   newStates[index] = !newStates[index];
      //   setAgendamentosModal(newStates);
      //   break;
      // case 'concluido':
      //   newStates = [...prevStates];
      //   newStates[index] = !newStates[index];
      //   setConcluidosModal(newStates);
      //   break;
      default:
        break;
    }
  };

  useEffect(() => {
    const get_processos = async () => {
      setLoading(true);
      try {
        const response = await post(proc.read.full, {
          token,
          roleCode,
          userCode,
        });
        const data = response.data;
        setArrayProcessos(data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    checkLogin();
    get_processos();
  }, [checkLogin, token, roleCode, userCode]);

  const handleUpdate = () => {
    const upd = updated;
    setUpdated(!upd);
  };

  useEffect(() => {
    const get_processo = async () => {
      setLoading(true);
      try {
        const response = await post(proc.read.codigo, {
          token,
          roleCode,
          userCode,
          codigo: processoCode,
        });

        const data = response.data;
        const organize_etapas = () => {
          const uniqueEtapas = [];

          if (data) {
            data.forEach((etapa) => {
              const existingEtapa = uniqueEtapas.find(
                (unique) => unique.id === etapa.processo_etapas_id,
              );

              if (!existingEtapa) {
                uniqueEtapas.push({
                  etapa: etapa.etapas_nome,
                  sequencia: etapa.etapas_sequencia,
                  concluida: etapa.processo_etapas_concluida,
                  dias: etapa.etapas_dias_estimativa,
                  id: etapa.processo_etapas_id,
                  processo_etapas_data_inicial:
                    etapa.processo_etapas_data_inicial,
                  processo_etapas_data_final: etapa.processo_etapas_data_final,
                  andamentos: data
                    .filter(
                      (and) =>
                        and.processo_etapas_id === etapa.processo_etapas_id &&
                        and.andamento_id !== null,
                    )
                    .map((and) => {
                      return {
                        etapa_id: and.processo_etapas_id,
                        visivel_cliente: and.visivel_cliente,
                        notificar_escritorio: and.notificar_escritorio,
                        notificacao_ativa: and.notificacao_ativa,
                        prioridade_id: and.prioridade_id,
                        prazo: and.prazo,
                        status_id: and.status_id,
                        title: and.title,
                        andamento_id: and.andamento_id,
                        andamento: and.andamento,
                        nota: and.nota,
                        codigo: and.codigo,
                        created_at: and.created_at,
                        updated_at: and.updated_at,
                        andamento_tipo: and.andamento_tipo,
                        prioridade: and.prioridade,
                        documento_type: and.documento_type,
                        files_name: and.files_name,
                        files_type: and.files_type,
                        created_by_nome: and.created_by_nome,
                        created_by_email: and.created_by_email,
                        created_by_codigo: and.created_by_codigo,
                      };
                    }),
                });
              }
            });
          }

          return uniqueEtapas;
        };

        const ets = organize_etapas();
        setEtapas(ets);
        if (data) setProcesso(data[0]);
        setData(data);
        setDocumentosModal(ets.map(() => false));
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    get_processo();
  }, [showSection, processoCode, roleCode, token, userCode]);

  const loadProcesso = () => {
    return (
      <div>
        <p className='mt-5 mb-3 softxt'>
          Acompanhe o andamento do seu processo
        </p>
        {etapas &&
          etapas.map((et, index) => {
            return (
              <div
                key={et.id}
                className={`div-etapa${
                  et.concluida
                    ? ' et-concluida'
                    : processo.etapa_id === et.id
                    ? ' et-atual'
                    : ' et-pendente'
                }`}
              >
                <>
                  {processo.etapa_id === et.id ? (
                    <p className='mt-3 mb-2 f08 softxt fc-blue d-flex flex-row align-content-center'>
                      <FaLocationDot className=' svg14' />
                      <span className='mx-2'>Você está aqui:</span>
                    </p>
                  ) : (
                    ''
                  )}

                  <div className='etapa-head'>
                    <div className='etapa-head-start'>
                      <h4 className='softxt'>
                        <span className='me-2'>
                          <EtapaIcon etapa={et.etapa} />
                        </span>
                        <span
                          className={`me-3 f08 softxt ${
                            et.concluida
                              ? ' fc-grey'
                              : processo.etapa_id === et.id
                              ? ' fc-blue'
                              : ''
                          }`}
                        >
                          {et.etapa}
                        </span>
                        <span className='c-green'>
                          {et.concluida ? <FaCircleCheck /> : ''}
                        </span>
                      </h4>

                      <span className=' ms-2 f08'>
                        <span className='mx-1'>
                          <VscDebugStart
                            className={`svg14 ${
                              et.concluida
                                ? ' fc-green'
                                : processo.etapa_id === et.id
                                ? ' fc-blue'
                                : ' fc-grey'
                            }`}
                          />
                        </span>
                        <span className='mx-1'>
                          {new Date(
                            `${et.processo_etapas_data_inicial}T00:00`,
                          ).toLocaleDateString('pt-BR')}
                        </span>
                        <span className='mx-1'>
                          <IoCheckmarkDoneOutline
                            className={`svg14 ${
                              et.concluida ? ' fc-green' : ' fc-grey-light'
                            }`}
                          />
                        </span>
                      </span>
                    </div>
                    <div className='toggle-button-div'>
                      <MdExpand
                        onClick={() => {
                          const el = document.getElementById(`ands_${et.id}`);
                          el.classList.toggle('div-hide');
                        }}
                      />
                    </div>
                  </div>
                </>
                <div
                  id={`ands_${et.id}`}
                  className={`${
                    processo.etapa_id === et.id ? ' div-atual' : ' div-hide'
                  }`}
                >
                  <div
                    className={`d-flex flex-row justify-content-between softxt f08 ${
                      etapas[index].concluida
                        ? 'topbuttons tb-done'
                        : 'topbuttons'
                    }`}
                  >
                    <div>
                      <OverlayTrigger
                        placement='top'
                        id={`tt_modal_docs_${et.id}`}
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Adicionar Documento</Tooltip>}
                      >
                        <>
                          <span>Adicionar documento</span>
                          <span className='c-green ms-2'>
                            <MdOutlineUploadFile
                              onClick={() =>
                                handleToggleModal(
                                  index,
                                  'documento',
                                  documentosModal,
                                )
                              }
                            />
                          </span>
                        </>
                      </OverlayTrigger>
                      <DocumentosModal
                        key={`modal_docs_${et.id}`}
                        show={documentosModal[index]}
                        updated={setUpdated}
                        docs={documentos}
                        proc_etapa_id={et.id}
                        procs={data[index]}
                        setShow={() =>
                          handleToggleModal(index, 'documento', documentosModal)
                        }
                      />
                    </div>
                  </div>
                  {et.andamentos.map((and) => {
                    return (
                      <AndamentosLine
                        key={and.codigo}
                        and={and}
                        upd={handleUpdate}
                        c={true}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  if (loading) {
    return (
      <>
        <LoadingDots />
      </>
    );
  }

  if (showSection === 'account') return <Account />;
  if (showSection === 'support') return <Support />;

  return (
    <div className='mb-5'>
      <h4 className='hc-section-title'>Meus Processos</h4>
      <table className='table table-striped table-hover'>
        <thead>
          <tr className='table-simulacoes hide600'>
            <th
              scope='col'
              className='softxt f08 txt-300'
            >
              Correspondente
            </th>

            <th
              scope='col'
              className='softxt f08 txt-300 hide1000'
            >
              Cidade
            </th>
            <th
              scope='col'
              className='softxt f08 txt-300'
            >
              Operação
            </th>
            <th
              scope='col'
              className='softxt f08 txt-300'
            >
              Valor
            </th>

            <th
              scope='col'
              className='softxt f08 txt-300'
            >
              Etapa Atual
            </th>
            <th
              scope='col'
              className='softxt f08 txt-300'
            ></th>
          </tr>
        </thead>
        <tbody>
          {arrayProcessos.length > 0 &&
            arrayProcessos.map(
              ({
                processo_codigo,
                correspondente_nome,
                cidade_imovel,
                operacao,
                valor_imovel,
                etapa,
              }) => (
                <tr
                  key={processo_codigo}
                  className='table-simulacoes'
                >
                  <td>{correspondente_nome}</td>
                  <td className='hide1000'>{cidade_imovel}</td>
                  <td>{operacao}</td>
                  <td>{curr(valor_imovel)}</td>
                  <td>
                    <EtapaIcon
                      etapa={etapa}
                      className='me-1'
                    />
                    {etapa}
                  </td>
                  <td>
                    <button
                      type='button'
                      className='btn btn-outline-light btn-sm'
                      data-toggle='tooltip'
                      title='Abrir processo'
                      onClick={(e) => {
                        e.preventDefault();
                        setProcessoCode(processo_codigo);
                      }}
                    >
                      <FcOpenedFolder />
                    </button>
                  </td>
                </tr>
              ),
            )}
        </tbody>
      </table>
      {processoCode !== '' ? loadProcesso(processoCode) : ''}
    </div>
  );
};

export default Client;
