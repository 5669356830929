import React, { useContext, useState } from 'react';
import { CgSearchLoading } from 'react-icons/cg';
import { FcDownload } from 'react-icons/fc';
import { MdExpand } from 'react-icons/md';
import '../assets/css/app/blobviewer.css';
import conf from '../config/conf';
import GlobalContext from '../context/GlobalContext';
import { and, sim_docs } from '../services/endpoints';

const BlobViewer = ({
  blobCode,
  blobType,
  blobIcon,
  blobName,
  docSim = false,
  cod_doc_sim = '',
  blobTitle = '',
}) => {
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const { store } = useContext(GlobalContext);
  const { token } = store();

  const loadBlobContent = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData();
      if (docSim) {
        form.append('simulacao_codigo', cod_doc_sim);
      }
      form.append('codigo', blobCode);
      form.append('files_type', blobType);
      form.append('token', token);

      const url = docSim
        ? `${conf.ORA}${sim_docs.read.doc}/`
        : `${conf.ORA}${and.read.docs}`;
      const response = await fetch(url, {
        method: 'POST',
        body: form,
      });

      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);
      setUrl(objectURL);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div className='d-flex flex-column'>
      <div className='d-flex flex-row justify-content-start align-items-center'>
        <span className='me-2'>{blobIcon}</span>

        <CgSearchLoading
          className='svg09 fc-blue me-1 btn-pointer'
          onClick={(e) => {
            loadBlobContent(e);
            const el = document.getElementById(`cont_doc_${blobCode}`);
            if (el.classList.contains('div-hide')) {
              el.classList.toggle('div-hide');
            }
          }}
        />
        <MdExpand
          className='svg09 fc-blue me-2 btn-pointer'
          onClick={(e) => {
            const el = document.getElementById(`cont_doc_${blobCode}`);
            el.classList.toggle('div-hide');
            if (!el.classList.contains('div-hide')) {
              if (!url) {
                loadBlobContent(e);
              }
            }
          }}
        />
        <span className='f08 me-2 fc-regular sp-and-content'>{blobTitle}</span>
        <span className='f07 me-2 fc-grey sp-and-content'>{blobName}</span>
      </div>
      <div
        className='content-doc div-hide f08 softxt'
        id={`cont_doc_${blobCode}`}
      >
        {loading ? (
          'Carregando...'
        ) : url ? (
          blobType.includes('image') ? (
            <>
              <div className='my-2 f09'>
                <a
                  href={url}
                  download
                >
                  <FcDownload className='svg14 fc-blue' />
                  <span className='ms-1'>Baixar Imagem</span>
                </a>
              </div>
              <img
                src={url}
                alt='img'
                className='img_iframe'
              />
            </>
          ) : blobType === 'application/pdf' ? (
            <iframe
              title='PDF Viewer'
              src={url}
              className='pdf_iframe'
            />
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default BlobViewer;
