import PropTypes from 'prop-types';
import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import conf from '../config/conf';

const LoadingDots = ({ class_op }) => (
  <div
    className={`d-flex flex-column justify-content-center align-items-center  text-center w100 h100 p-3 ${class_op}`}
  >
    {/* <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div> */}
    <img
      src={conf.images.logotext512}
      className='my-3 w60 mw200'
      alt='logo'
    />
    <ThreeDots
      visible={true}
      height='80'
      width='80'
      color='#0084ff'
      radius='9'
      ariaLabel='three-dots-loading'
      wrapperStyle={{}}
      wrapperClass=''
    />
    <p className='mt-4 mb-2 f08  softxt'>Aguarde...</p>
  </div>
);

LoadingDots.propTypes = {
  class_op: PropTypes.string,
};

export default LoadingDots;
