import React, { useContext } from 'react';
import { AiOutlineAppstore } from 'react-icons/ai';
import GlobalContext from '../../context/GlobalContext';

export default function AppButton() {
  const { navigate } = useContext(GlobalContext);
  return (
    <span
      className='btn outline-secondary w-btn-hover'
      onClick={() => {
        navigate('/app');
      }}
    >
      <AiOutlineAppstore />
    </span>
  );
}
