import { FaUserLock } from 'react-icons/fa';
import { links } from '../../config/conf';

export default function LoginButton() {
  return (
    <a
      href={links.login}
      target='_blank'
      rel='noreferrer'
      className='btn outline-secondary w-btn-hover'
    >
      <FaUserLock />
    </a>
  );
}
