import { links } from '../../config/conf';
import IconsUI, { ui } from '../IconsUI';

export default function TelegramButton() {
  return (
    <a
      href={links.telegram}
      target='_blank'
      rel='noreferrer'
      className='btn outline-secondary w-btn-hover'
    >
      <IconsUI info={ui.social.telegram} />
    </a>
  );
}
