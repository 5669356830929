import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import GlobalContext from '../context/GlobalContext';
import { post } from '../services/requests';
import LoadingDots from '../components/LoadingDots';
import { user } from '../services/endpoints';
import NavReturn from '../components/NavReturn';

export default function User() {
  const { checkLogin, navigate } = useContext(GlobalContext);
  const { codigo } = useParams();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [logged, setLogged] = useState(true);

  useEffect(() => {
    const status = checkLogin();
    setLogged(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const get_user = async () => {
      setLoading(true);
      try {
        const response = await post(user.read.user, { codigo });
        console.log(response.data);
        setData(response.data[0]);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    get_user();
  }, [codigo]);

  if (!logged) {
    navigate('/logout');
  } else {
    if (loading) {
      return (
        <>
          <LoadingDots />
        </>
      );
    }

    return (
      <div className='habita-main'>
        <NavBar />
        <div className='habita-content mb-5'>
          <div className='habita-main-content'>
            <NavReturn info={data.NOME} />
            <table className='mt-2 mb-1 f09'>
              <tbody>
                <tr>
                  <td className='py-1 px-3'>Nome</td>
                  <td className='py-1 px-3'>{data.NOME}</td>
                </tr>
                <tr>
                  <td className='py-1 px-3'>CPF</td>
                  <td className='py-1 px-3'>{data.CPF}</td>
                </tr>
                <tr>
                  <td className='py-1 px-3'>Email</td>
                  <td className='py-1 px-3'>{data.EMAIL}</td>
                </tr>
                <tr>
                  <td className='py-1 px-3'>Telefone</td>
                  <td className='py-1 px-3'>{data.TELEFONE}</td>
                </tr>
                <tr>
                  <td className='py-1 px-3'>Perfil</td>
                  <td className='py-1 px-3'>{data.ROLE_DESCRIPTION}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
