import { FaWhatsapp } from 'react-icons/fa';
import { links } from '../../config/conf';

export default function WhatsappButton() {
  return (
    <a
      href={links.whatsapp}
      target='_blank'
      rel='noreferrer'
      className='btn outline-secondary w-btn-hover'
    >
      <FaWhatsapp />
    </a>
  );
}
