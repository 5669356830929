import { useContext, useEffect } from 'react';
import GlobalContext from '../context/GlobalContext';

function Logout() {
  const { logout, navigate } = useContext(GlobalContext);

  useEffect(() => {
    logout();
    navigate('/');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

}

export default Logout;
