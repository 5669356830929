import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../context/GlobalContext';

import Home from './Public';
import Administrador from './dashboards/Administrador';
import Client from './dashboards/Client';
import Correspondente from './dashboards/Correspondente';
import Manager from './dashboards/Manager';
import Office from './dashboards/Office';
import RealState from './dashboards/RealState';
import System from './dashboards/System';

import Footer from '../components/Footer';
import NavBar from '../components/NavBar';

function Dashboard() {
  const { checkLogin, store } = useContext(GlobalContext);
  const [logged, setLogged] = useState(false);
  // const [user, setUser] = useState({});

  useEffect(() => {
    const status = checkLogin();
    setLogged(status);
    // async function data() {
    //   const res = await userData();
    //   setUser(res);
    // }
    // data();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { role } = store();

  // const handleLogout = () => {
  //   logout();
  // }

  const selectDashboard = () => {
    switch (role) {
      case '1C9DBBA194FAE1B7E063845B000AE1AE':
        return (
          <>
            <System />
          </>
        );
      case '1C9DBBA194FBE1B7E063845B000AE1AE':
        return (
          <>
            <Administrador />
          </>
        );

      case '1C9DBBA194FCE1B7E063845B000AE1AE':
        return (
          <>
            <Manager />
          </>
        );

      case '1C9DBBA194FDE1B7E063845B000AE1AE':
        return (
          <>
            <Correspondente />
          </>
        );

      case '1C9DBBA194FEE1B7E063845B000AE1AE':
        return (
          <>
            <Office />
          </>
        );

      case '1C9DBBA194FFE1B7E063845B000AE1AE':
        return (
          <>
            <RealState />
          </>
        );

      case '1C9DBBA19500E1B7E063845B000AE1AE':
        return (
          <>
            <Client />
          </>
        );

      default:
        // handleLogout();
        console.log(role);
        return (
          <>
            <System />
          </>
        );
    }
  };

  if (logged) {
    return (
      <div className='habita-main'>
        <NavBar />
        <div className='habita-content'>
          <div className='habita-main-content'>{selectDashboard()}</div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <>
      <Home />
    </>
  );
}

export default Dashboard;
