import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { curr } from '../assets/js/utils';
import Loading from './Loading';

const NewsCharts = ({ data }) => {
  const [useData, setUseData] = useState([]);
  const [lines, setLines] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRandomColor = () => {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  };

  useEffect(() => {
    setLoading(true);

    const transformData = (data) => {
      const transformedData = [];
      const lineKeys = new Set();

      const currentMonth = new Date();
      const labels = Array.from({ length: 12 }, (_, index) => {
        const date = new Date(currentMonth);
        date.setMonth(currentMonth.getMonth() - index);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear().toString().slice(-2);
        return `${month}/${year}`;
      }); // Reverse to align month1 with the current month

      data.reduce((acc, { LINHA, TENANT_CODIGO, ...months }) => {
        acc[LINHA] = months;
        lineKeys.add(LINHA);
        return acc;
      }, {});

      labels.forEach((label, index) => {
        const monthData = { name: label };
        data.forEach((item) => {
          const linha = item.LINHA;
          monthData[linha] = parseFloat(item[`MONTH${index + 1}`]) || 0;
        });
        transformedData.push(monthData);
      });

      transformedData.reverse();

      setLines(Array.from(lineKeys));
      setUseData(transformedData);
      setLoading(false);
    };

    transformData(data);
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  return (
    <ResponsiveContainer
      width='100%'
      height={500}
      className='f08 fc-regular'
    >
      <LineChart
        data={useData}
        margin={{ top: 25, right: 10, left: 50, bottom: 15 }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis tickFormatter={(value) => curr(value)} />
        <Tooltip formatter={(value, name) => [`${curr(value)}`, name]} />
        <Legend />
        {lines.map((line) => (
          <Line
            key={`line_${line}`}
            type='monotone'
            dataKey={line}
            stroke={getRandomColor()}
            activeDot={{ r: 8 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default NewsCharts;
