import PropTypes from 'prop-types';
import React from 'react';

const Loading = ({ class_op }) => (
  <div className={`text-center w100 p-3 ${class_op}`}>
    <div
      className='spinner-border text-primary'
      role='status'
    >
      <span className='visually-hidden'>Loading...</span>
    </div>
  </div>
);

Loading.propTypes = {
  class_op: PropTypes.string,
};

export default Loading;
