import PropTypes from 'prop-types';

function SelectOptions({ docs, prop, id }) {
  return (
    <>
      <option
        key='default_option'
        value=''
        name='default_option'
        id='default_option'
      ></option>

      {docs.map((doc) => (
        <option
          key={doc[prop]}
          value={doc[prop]}
          name={id}
          id={id}
        >
          {doc[prop]}
        </option>
      ))}
    </>
  );
}

SelectOptions.propTypes = {
  docs: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  prop: PropTypes.string.isRequired,
};

export default SelectOptions;
