import React, { useContext, useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { BiTask } from 'react-icons/bi';
import { CiEdit } from 'react-icons/ci';
import { FaWhatsapp } from 'react-icons/fa';
import { FcOpenedFolder } from 'react-icons/fc';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { MdDeleteForever } from 'react-icons/md';
import { create_link_whatsapp } from '../../assets/js/utils';
import Loading from '../../components/Loading';
import PriorityDropdown from '../../components/PriorityDropdown';
import StatusDropdown from '../../components/StatusDropdown';
import GlobalContext from '../../context/GlobalContext';
import { and, task } from '../../services/endpoints';
import { post } from '../../services/requests';

function Tasks() {
  const { checkLogin, status, prioridades } = useContext(GlobalContext);
  const [tasks, setTasks] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [show, setShow] = useState(false);

  const [prioridade, setPrioridade] = useState(3);
  const [prazo, setPrazo] = useState(new Date().toISOString().split('T')[0]);
  const [title, setTitle] = useState('');
  const [andamento, setAndamento] = useState('');
  const [stats, setStatus] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePrioridade = (novaPrioridade) => {
    setPrioridade(novaPrioridade);
  };

  const handleStatus = (novoStatus) => {
    setStatus(novoStatus);
  };

  const handleSave = async () => {
    checkLogin();
    setLoading(true);
    try {
      setLoading(true);
      await post(task.create, {
        prioridade_id: prioridade,
        status_id: stats,
        prazo: prazo,
        title: title,
        andamento: andamento,
      });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);

    reloadTasks();
    setTimeout(() => {
      setLoading(false);
      setShow(false);
    }, 1000);
  };
  const cardColor = (prioridade) => {
    switch (prioridade) {
      case 1:
        return 'card-bg-urgente';
      case 2:
        return 'card-bg-alta';
      case 3:
        return 'card-bg-media';
      case 4:
        return 'card-bg-baixa';
      default:
        return 'card-bg-media';
    }
  };

  const checkPrazo = (prazo, stats) => {
    if (stats !== 4) {
      const today = new Date();
      const prazoDate = new Date(prazo);
      if (prazoDate < today) {
        return (
          <Badge
            bg='danger'
            className='txt-300 f08 me-1'
          >
            Atrasado
          </Badge>
        );
      } else {
        const timeDifference = prazoDate.getTime() - today.getTime();
        const oneDay = 1000 * 60 * 60 * 24;
        if (timeDifference > oneDay) {
          return '';
        } else {
          return (
            <Badge
              bg='warning'
              className='txt-300 f08 me-1'
            >
              Amanhã
            </Badge>
          );
        }
      }
    } else {
      return '';
    }
  };

  const filterTasks = (terms) => {
    if (terms.length > 2) {
      const filteredArray = tasks.filter(
        ({
          title,
          andamento,
          prazo,
          cliente_nome,
          cliente_telefone,
          cliente_email,
          correspondente_nome,
          corretor_nome,
          imobiliaria_nome,
          prioridade,
          status,
          cidade_imovel,
        }) =>
          title.toLowerCase().includes(terms.toLowerCase()) ||
          andamento.toLowerCase().includes(terms.toLowerCase()) ||
          prazo.toLowerCase().includes(terms.toLowerCase()) ||
          cliente_nome.toLowerCase().includes(terms.toLowerCase()) ||
          cliente_telefone.toLowerCase().includes(terms.toLowerCase()) ||
          cliente_email.toLowerCase().includes(terms.toLowerCase()) ||
          correspondente_nome.toLowerCase().includes(terms.toLowerCase()) ||
          corretor_nome.toLowerCase().includes(terms.toLowerCase()) ||
          imobiliaria_nome.toLowerCase().includes(terms.toLowerCase()) ||
          prioridade.toLowerCase().includes(terms.toLowerCase()) ||
          status.toLowerCase().includes(terms.toLowerCase()) ||
          cidade_imovel.toLowerCase().includes(terms.toLowerCase()),
      );
      setFiltered(filteredArray);
    } else {
      setFiltered(tasks);
    }
  };

  const reloadTasks = () => {
    setReload(!reload);
  };

  const updateStatus = async (taskCode, status, tipo) => {
    const endpoint = tipo === 'task' ? task.update.status : and.update.status;
    try {
      await post(endpoint, {
        codigo: taskCode,
        status,
      });
      reloadTasks();
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const updatePrazo = async (taskCode, prazo, tipo) => {
    const endpoint = tipo === 'task' ? task.update.prazo : and.update.prazo;
    try {
      await post(endpoint, {
        codigo: taskCode,
        prazo,
      });
      reloadTasks();
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // const updateTitle = async (taskCode, title, tipo) => {
  //   const endpoint = tipo === 'task' ? task.update.title : and.update.title;
  //   try {
  //     await post(endpoint, {
  //       token,
  //       roleCode,
  //       userCode,
  //       codigo: taskCode,
  //       title,
  //     });
  //     reloadTasks();
  //   } catch (error) {
  //     console.log(error.response.data.message);
  //   }
  // };

  // const updateAndamento = async (taskCode, andamento, tipo) => {
  //   const endpoint =
  //     tipo === 'task' ? task.update.andamento : and.update.andamento;
  //   try {
  //     await post(endpoint, {
  //       token,
  //       roleCode,
  //       userCode,
  //       codigo: taskCode,
  //       andamento,
  //     });
  //     reloadTasks();
  //   } catch (error) {
  //     console.log(error.response.data.message);
  //   }
  // };

  const deleteTask = async (taskCode, tipo) => {
    const endpoint = tipo === 'task' ? task.delete : and.delete;
    try {
      await post(endpoint, {
        codigo: taskCode,
      });
      reloadTasks();
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    const getTasks = async () => {
      setLoading(true);
      try {
        const response = await post(and.read.tasks, {});
        const data = response.data;
        setTasks(data);
        setFiltered(data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    checkLogin();
    getTasks();
  }, [checkLogin, reload]);

  const handleDragStart = (e, taskCode, taskTipo) => {
    e.dataTransfer.setData('taskCode', taskCode);
    e.dataTransfer.setData('tipo', taskTipo);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, status) => {
    e.preventDefault();
    const taskCode = e.dataTransfer.getData('taskCode');
    const tipo = e.dataTransfer.getData('tipo');
    updateStatus(taskCode, status, tipo);
  };

  if (loading)
    return (
      <>
        <Loading />
      </>
    );

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title className='softxt'>Nova task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='align-items-center gocol991'>
            <Form.Control
              required
              type='text'
              id='title'
              value={title}
              placeholder='Título'
              className='form-control input-cur w-100 my-1'
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            <div className='gocol500 align-items-center'>
              <div className='d-flex flex-row  align-items-center'>
                <span className='f08 mx-1'>Prazo</span>
                <Form.Control
                  type='date'
                  className=''
                  size='sm'
                  value={prazo}
                  id={`new_prazo_task_${null}`}
                  onChange={(e) => {
                    setPrazo(e.target.value);
                  }}
                />
              </div>
              <PriorityDropdown
                prioridades={prioridades}
                prior={prioridade}
                processo_etapa_id={null}
                onPrioridadeChange={handlePrioridade}
                showName={true}
              />
              <StatusDropdown
                status={status}
                stat={stats}
                processo_etapa_id={null}
                onStatusChange={handleStatus}
              />
            </div>
          </div>
          <div>
            <Form.Label>
              <span className='me-1'>
                <BiTask className='fc-green' />
              </span>
              <span className='f08 softxt'>Task</span>
            </Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              value={andamento}
              id={`new_andamento_${null}`}
              onChange={(e) => {
                setAndamento(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='success'
            onClick={handleSave}
          >
            Adicionar
          </Button>
        </Modal.Footer>
      </Modal>

      <p className='mt-2 mb-5'>
        <span className='me-2 softxt'>Filtrar por</span>
        <input
          type='text'
          id='filter-tasks'
          className='filter-table'
          onChange={(e) => filterTasks(e.target.value)}
          pattern='.{3,20}'
        />
        <Button
          className='f08 ms-2'
          variant='outline-success'
          size='sm'
          onClick={handleShow}
        >
          <IoIosAddCircleOutline className='me-1' />
          <span>Adicionar task</span>
        </Button>
      </p>

      <div className='gocol991 justify-content-between'>
        {status &&
          status.map((st) => (
            <div
              key={`div_status_${st.c}`}
              className='d-flex flex-column mx-2 text-center p-3 m-2 w25 min200p ovew'
            >
              <p
                key={`title_${st.c}`}
                className='hc-section-title'
              >
                {st.nome}
              </p>
              <div
                key={`indiv_${st.c}`}
                className='d-flex flex-column card-col'
                onTouchEnd={(e) => handleDrop(e, st.c)}
                onTouchMove={(e) => handleDragOver(e)}
                onDragOver={(e) => handleDragOver(e)}
                onDrop={(e) => handleDrop(e, st.c)}
              >
                {filtered &&
                  filtered.map((task) => {
                    if (task.status_id === parseInt(st.c)) {
                      return (
                        <div
                          key={`filter_task_${task.codigo}`}
                          className={`card-task p-3 my-1  ${
                            task.status_id !== 4
                              ? cardColor(task.prioridade_id)
                              : ''
                          }`}
                          draggable
                          onTouchStart={(e) =>
                            handleDragStart(e, task.codigo, task.tipo)
                          }
                          onDragStart={(e) =>
                            handleDragStart(e, task.codigo, task.tipo)
                          }
                        >
                          <div className='d-flex flex-row align-items-center'>
                            {checkPrazo(task.prazo, task.status_id)}
                            <Form.Control
                              key={`new_prazo_task_${task.id}`}
                              type='date'
                              className='f08'
                              size='sm'
                              value={task.prazo}
                              id={`new_prazo_task_${task.id}`}
                              onChange={(e) =>
                                updatePrazo(
                                  task.codigo,
                                  e.target.value,
                                  task.tipo,
                                )
                              }
                            />
                            <span>
                              <MdDeleteForever
                                key={`delete_task_${task.id}`}
                                className='ms-1 svg16 card-edit'
                                onClick={() =>
                                  deleteTask(task.codigo, task.tipo)
                                }
                              />
                            </span>

                            <PriorityDropdown
                              key={`prior_task_${task.id}`}
                              prioridades={prioridades}
                              prior={task.prioridade_id}
                              codigo={task.codigo}
                              tipo={task.tipo}
                              processo_etapa_id={task.etapa_id}
                              onPrioridadeChange={handlePrioridade}
                              showBadge={false}
                              showName={false}
                              upd={reloadTasks}
                              small={true}
                            />
                          </div>
                          <p className='card-title '>
                            <CiEdit className='me-2 card-edit' />
                            {task.title}
                          </p>
                          <p>
                            <CiEdit className='me-2 card-edit' />
                            {task.andamento}
                          </p>

                          <p className='my-1'>
                            {task.cliente_telefone ? (
                              <a
                                href={create_link_whatsapp(
                                  task.cliente_telefone,
                                )}
                                target='_blank'
                                rel='noreferrer'
                              >
                                <FaWhatsapp className='me-1 svg14' />
                                {task.cliente_nome}
                                {', '}
                                {task.cliente_telefone}
                              </a>
                            ) : (
                              ''
                            )}
                          </p>
                          {task.corretor_nome !== 'Nenhum' &&
                          task.corretor_nome !== null ? (
                            task.imobiliaria_nome !== 'Nenhuma' &&
                            task.imobiliaria_nome !== null ? (
                              <p className='mb-1'>
                                `${task.corretor_nome}(${task.imobiliaria_nome}
                                )`
                              </p>
                            ) : (
                              <p className='mb-1'>{task.corretor_nome}</p>
                            )
                          ) : (
                            ''
                          )}

                          <p className='my-0'>
                            {task.processo_codigo ? (
                              <a
                                href={`/processos/${task.processo_codigo}`}
                                target='_blank'
                                rel='noreferrer'
                              >
                                <FcOpenedFolder className='me-1 svg14' />
                                Abrir Processo
                              </a>
                            ) : (
                              ''
                            )}
                          </p>
                        </div>
                      );
                    } else {
                      return '';
                    }
                  })}
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default Tasks;
