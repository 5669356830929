import { BiRegistered } from 'react-icons/bi';
import { MdCopyright } from 'react-icons/md';
import { setup } from '../config/conf';
import EmailButton from './buttons/EmailButton';
import InstagramButton from './buttons/InstagramButton';
import TelegramButton from './buttons/TelegramButton';
import WhatsappButton from './buttons/WhatsappButton';

export default function Footer() {
  const y = new Date().getFullYear();

  return (
    <footer className='footer'>
      <div className='d-flex flex-row justify-content-center align-items-baseline f08'>
        <p className='footer-sp-fale'>Fale conosco: </p>
        <InstagramButton />
        <WhatsappButton />
        <TelegramButton />
        <EmailButton />
      </div>
      <span className='text-center footer-sp-reg f08'>
        {setup.title} <BiRegistered /> Todos os direitos reservados{' '}
        <MdCopyright /> {y}
      </span>
    </footer>
  );
}
