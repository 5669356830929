import { useContext, useEffect, useState } from 'react';
import IconsUI, { ui } from '../../components/IconsUI';
import Loading from '../../components/Loading';
import SimulacaoLine from '../../components/SimulacaoLine';
import GlobalContext from '../../context/GlobalContext';
import { sim } from '../../services/endpoints';
import * as api from '../../services/requests';
// import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function Simulacoes() {
  const { checkLogin } = useContext(GlobalContext);
  const [arraySimulacoes, setArraySimulacoes] = useState([]);
  const [filtered, setFiltered] = useState(arraySimulacoes);
  const [loading, setLoading] = useState(true);
  const [terms, setTerms] = useState('');
  const [update, setUpdate] = useState(false);

  const [searchtxt, setSearchtxt] = useState('');
 //  const [inativos, setInativos] = useState(0);

  useEffect(() => {
    const get_simulacoes = async () => {
      setLoading(true);
      try {
        const response = await api.post(sim.read, {});
        const data = response.data;
        if (data.success === false) {
          console.log('Erro ao carregar simulações');
        } else {
          const ordered = data.sort((a, b) => b.SIM_ID - a.SIM_ID);
          setArraySimulacoes(ordered);
          setFiltered(ordered);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    checkLogin();
    get_simulacoes();
  }, [checkLogin, update]);

  const handleInputChange = (event) => {
    setTerms(event.target.value);
  };

  const handleUpdate = () => {
    setUpdate(!update);
  };

  const handleSearchDatabase = async () => {
    try {
      const response = await api.post(sim.find, {
        searchtxt,
        // inativos,
      });
      const data = response.data;
      if (data.success === false) {
        console.log('Erro ao carregar simulações');
      } else {
        setArraySimulacoes(data);
        setFiltered(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (terms.length > 2) {
      const filtered_array = arraySimulacoes.filter(
        ({
          USER_NOME,
          USER_CPF,
          USER_EMAIL,
          USER_TELEFONE,
          SIM_IMOVEL_CIDADE,
          SIM_IMOVEL_DESCRICAO,
        }) =>
          (USER_NOME &&
            USER_NOME.toLowerCase().includes(terms.toLowerCase())) ||
          (USER_CPF && USER_CPF.includes(terms)) ||
          (USER_EMAIL &&
            USER_EMAIL.toLowerCase().includes(terms.toLowerCase())) ||
          (USER_TELEFONE && USER_TELEFONE.includes(terms)) ||
          (SIM_IMOVEL_CIDADE &&
            SIM_IMOVEL_CIDADE.toLowerCase().includes(terms.toLowerCase())) ||
          (SIM_IMOVEL_DESCRICAO &&
            SIM_IMOVEL_DESCRICAO.toLowerCase().includes(terms.toLowerCase())),
      );
      setFiltered(filtered_array);
    } else {
      setFiltered(arraySimulacoes);
    }
  }, [terms, arraySimulacoes]);

  if (loading)
    return (
      <>
        <Loading />
      </>
    );

  return (
    <>
      <div className='d-flex flex-row align-items-center justify-content-between fc-blue '>
        <h4 className='hc-section-title'>Simulações</h4>
        <IconsUI
          info={ui.action.update}
          clsnm='svg14 hov-green'
          click={handleUpdate}
          tooltip='Atualizar lista de simulações'
        />
      </div>
      <div className='d-flex flex-row justify-content-around'>
        <div className='mt-2 mb-5 col-5 d-flex flex-column'>
          <span className='me-2 softxt f09'>
            Filtrar por nome, cpf, email, telefone, cidade ou operação
          </span>
          <input
            type='text'
            id='filter-sim'
            className='filter-table w100'
            onChange={handleInputChange}
            pattern='.{3,20}'
          />
        </div>
        <div className='mt-2 mb-5 col-5 d-flex flex-column'>
          <span className='me-2 softxt'>Pesquisa avançada</span>
          <div className='d-flex flex-row align-items-center'>
            <input
              type='text'
              id='filter-sim-db'
              className='filter-table me-2'
              onChange={(e) => setSearchtxt(e.target.value)}
              pattern='.{3,20}'
            />
            <Button
              variant='outline-success'
              size='sm'
              onClick={handleSearchDatabase}
            >
              Buscar
            </Button>
          </div>
        </div>
      </div>
      <table className='table table-striped table-hover'>
        <tbody>
          {filtered &&
            filtered.map((sim) => (
              <SimulacaoLine
                key={sim.SIM_ID}
                obj={sim}
                upd={handleUpdate}
              />
            ))}
        </tbody>
      </table>
    </>
  );
}

export default Simulacoes;
