import { useState } from 'react';
// import { useContext, useState, useEffect } from 'react';
// import GlobalContext from '../../context/GlobalContext';
// import { post } from '../../services/requests';
// import { user } from '../../services/endpoints';
// import Loading from '../../components/Loading';
// import Badge from 'react-bootstrap/Badge';
// import Spinner from 'react-bootstrap/Spinner';
// import Form from 'react-bootstrap/Form';
import AdminCustom from '../../components/AdminCustom';
import AdminFiliais from '../../components/AdminFiliais';
import AdminUsers from '../../components/AdminUsers';

function Admin() {
  // const { checkLogin, token, roleCode, userCode } = useContext(GlobalContext);
  // const [arrayUsers, setArrayUsers] = useState([]);
  // const [filtered, setFiltered] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [section, setSection] = useState('users');

  const sections = () => {
    switch (section) {
      case 'users':
        return <AdminUsers />;
      case 'filiais':
        return <AdminFiliais />;
      case 'custom':
        return <AdminCustom />;
      default:
        return <AdminUsers />;
    }
  };

  return (
    <>
      <h4 className='hc-section-title'>Administração</h4>
      <p className='mt-2 mb-5'>
        <span className='me-2 softxt'>Selecione a seção</span>
        <select
          className='filter-table'
          onChange={(event) => setSection(event.target.value)}
        >
          <option value='users'>Usuários</option>
          <option value='filiais'>Filiais</option>
          <option value='custom'>Personalizar</option>
        </select>
      </p>
      {sections()}
    </>
  );
}

//   useEffect(() => {
//     const get_users = async () => {
//       setLoading(true);
//       try {
//         const response = await post(user.read.gestao, {
//           token,
//           roleCode,
//           userCode,
//         });
//         const data = response.data;
//         console.log(data)
//         setArrayUsers(data);
//         setFiltered(data);
//       } catch (error) {
//         console.log(error.response.data.message);
//       }
//       setLoading(false);
//     };

//     checkLogin();
//     get_users();
//   }, [checkLogin, token, roleCode, userCode]);

//   if (loading)
//     return (
//       <>
//         <Loading />
//       </>
//     );

//   const filter_users = () => {
//     const terms = document.getElementById('filter-users').value;

//     if (terms.length > 2) {
//       let filtered = arrayUsers.filter(
//         ({ nome, cpf, email, telefone, role, role_description }) =>
//           nome.toLowerCase().includes(terms.toLowerCase()) ||
//           cpf.toLowerCase().includes(terms.toLowerCase()) ||
//           email.toLowerCase().includes(terms.toLowerCase()) ||
//           telefone.toLowerCase().includes(terms.toLowerCase()) ||
//           role.includes(terms.toLowerCase()) ||
//           role_description.toLowerCase().includes(terms.toLowerCase())
//       );
//       setFiltered(filtered);
//     }

//     if (terms.length < 3) {
//       setFiltered(arrayUsers);
//     }
//   };

//   const roles = [
//     {
//       id: 2,
//       role: 'admin',
//       description: 'Administrador',
//     },
//     {
//       id: 3,
//       role: 'gestor',
//       description: 'Gestor',
//     },
//     {
//       id: 4,
//       role: 'corresp',
//       description: 'Correspondente',
//     },
//     {
//       id: 5,
//       role: 'interno',
//       description: 'Interno',
//     },
//     {
//       id: 6,
//       role: 'corretor',
//       description: 'Corretor',
//     },
//     {
//       id: 7,
//       role: 'cliente',
//       description: 'Cliente',
//     },
//   ];

//   const button_on = (user) => {
//     if (loading)
//       return <Spinner animation="border" variant="success" size="sm" />;
//     if (user.active)
//       return (
//         <Badge className="txt-200 bad-35" bg="success">
//           ON
//         </Badge>
//       );
//     return (
//       <Badge className="txt-200 bad-35" bg="dark">
//         OFF
//       </Badge>
//     );
//   };

//   return (
//     <>
//       <h4 className="hc-section-title">Usuários</h4>
//       <p className="mt-2 mb-5">
//         <span className="me-2 softxt">Filtrar por</span>
//         <input
//           type="text"
//           id="filter-users"
//           className="filter-table"
//           onChange={filter_users}
//           pattern=".{3,20}"
//         />
//       </p>
//       <table className="table table-striped table-hover f08">
//         <thead>
//           <tr>
//             <th>Nome</th>
//             <th>CPF</th>
//             <th>Email</th>
//             <th>Telefone</th>
//             <th>Perfil</th>
//             <th>Ativo</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filtered.map((usr) => (
//             <tr
//               key={`tr_profile_${usr.codigo}`}
//               className={usr.active ? 'table-users' : 'table-users-off'}
//             >
//               <td>{usr.nome}</td>
//               <td>{usr.cpf}</td>
//               <td>{usr.email}</td>
//               <td>{usr.telefone}</td>
//               <td>
//                 <Form.Select
//                   aria-label="perfil"
//                   size="sm"
//                   onChange={async (event) => {
//                     let codigo = usr.codigo;
//                     let updated_role = event.target.value;
//                     let role = roles.find(
//                       (r) => r.description === updated_role
//                     );
//                     try {
//                       setLoading(true);
//                       const res = await post(user.update.role, {
//                         token,
//                         roleCode,
//                         userCode,
//                         codigo,
//                         role: role.id,
//                       });
//                       console.log(res)
//                       setFiltered((prev) =>
//                         prev.map((u) => {
//                           if (u.codigo === usr.codigo) {
//                             u.role_description = updated_role;
//                             u.role = role.id;
//                           }
//                           return u;
//                         })
//                       );
//                       setLoading(false);
//                     } catch (error) {
//                       console.error(error);
//                       setLoading(false);

//                     }
//                   }}
//                 >
//                   {roles.map((role) => (
//                     <option
//                       key={`${role.id}_${usr.codigo}`}
//                       value={role.description}
//                       selected={role.description === usr.role_description}
//                     >
//                       {role.description}
//                     </option>
//                   ))}
//                 </Form.Select>
//               </td>
//               <td>
//                 <span
//                   className="me-1"
//                   data-toggle="tooltip"
//                   title={usr.active ? 'Desativar' : 'Ativar'}
//                   onClick={async () => {
//                     let codigo = usr.codigo;
//                     let updated_active = !usr.active;

//                     try {
//                       setLoading(true);
//                       await post(user.update.active, {
//                         token,
//                         roleCode,
//                         userCode,
//                         codigo,
//                         active: updated_active,
//                       });
//                       setFiltered((prev) =>
//                         prev.map((u) => {
//                           if (u.codigo === usr.codigo) {
//                             u.active = updated_active;
//                           }
//                           return u;
//                         })
//                       );
//                       // usr.active = updated_active;
//                     } catch (error) {
//                       console.error(error);
//                     }
//                     setLoading(false);
//                   }}
//                 >
//                   {button_on(usr)}
//                 </span>
//                 {/* {user.active ? 'Sim' : 'Não'} */}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </>
//   );
// }

export default Admin;
