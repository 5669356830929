import React from 'react';
import Account from '../pages/sections/Account';
import Admin from '../pages/sections/Admin';
import Clientes from '../pages/sections/Clientes';
import Financiamentos from '../pages/sections/Financiamentos';
import News from '../pages/sections/News';
import Processos from '../pages/sections/Processos';
import Simulacoes from '../pages/sections/Simulacoes';
import Support from '../pages/sections/Support';
import Tasks from '../pages/sections/Tasks';
import Authorize from '../services/Authorize';

function RenderSection({ section }) {
  const role_id = sessionStorage.getItem('@App:ri');

  switch (section) {
    case 'account':
      return (
        <div className='mb-5'>
          <hr className='mt-5 mb-4' />
          <Account />
        </div>
      );
    case 'news':
      if (Authorize(role_id, 3))
        return (
          <div className='mb-5'>
            <hr className='mt-5 mb-4' />
            <News />
          </div>
        );
      break;

    case 'simulacoes':
      if (Authorize(role_id, 5))
        return (
          <div className='mb-5'>
            <hr className='mt-5 mb-4' />
            <Simulacoes />
          </div>
        );
      break;
    case 'financiamentos':
      if (Authorize(role_id, 5))
        return (
          <div className='mb-5'>
            <hr className='mt-5 mb-4' />
            <Financiamentos />
          </div>
        );
      break;
    case 'tasks':
      if (Authorize(role_id, 5))
        return (
          <div className='mb-5'>
            <hr className='mt-5 mb-4' />
            <Tasks />
          </div>
        );
      break;
    case 'processos':
      if (Authorize(role_id, 5))
        return (
          <div className='mb-5'>
            <hr className='mt-5 mb-4' />
            <Processos />
          </div>
        );
      break;
    case 'clientes':
      if (Authorize(role_id, 5))
        return (
          <div className='mb-5'>
            <hr className='mt-5 mb-4' />
            <Clientes />
          </div>
        );
      break;

    case 'admin':
      if (Authorize(role_id, 3))
        return (
          <div className='mb-5'>
            <hr className='mt-5 mb-4' />
            <Admin />
          </div>
        );
      break;
    case 'support':
      return (
        <div className='mb-5'>
          <hr className='mt-5 mb-4' />
          <Support />
        </div>
      );
    default:
      return '';
  }
}

export default RenderSection;
