import { useContext, useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Loading from '../components/Loading';
import GlobalContext from '../context/GlobalContext';
import { user } from '../services/endpoints';
import { post } from '../services/requests';
import IconsUI, { ui } from './IconsUI';

function AdminUsers() {
  const { checkLogin, navigate } = useContext(GlobalContext);
  const [arrayData, setArrayData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);

  const get_users = async () => {
    setLoading(true);
    try {
      const response = await post(user.read.gestao, {});
      const data = response.data;
      setArrayData(data);
      setFiltered(data);
    } catch (error) {
      console.log(error.response.data.message);
    }
    setLoading(false);
  };

  useEffect(() => {    
    const logged = checkLogin();
    if (logged) get_users();
    get_users();
  }, [checkLogin]);

  if (loading)
    return (
      <>
        <Loading />
      </>
    );

  const filter = () => {
    const terms = document.getElementById('filter-users').value;

    if (terms.length > 2) {
      let filtered = arrayData.filter(
        ({ NOME, CPF, EMAIL, TELEFONE, ROLE, ROLE_DESCRIPTION }) =>
          NOME.toLowerCase().includes(terms.toLowerCase()) ||
          CPF.toLowerCase().includes(terms.toLowerCase()) ||
          EMAIL.toLowerCase().includes(terms.toLowerCase()) ||
          TELEFONE.toLowerCase().includes(terms.toLowerCase()) ||
          ROLE.toLowerCase().includes(terms.toLowerCase()) ||
          ROLE_DESCRIPTION.toLowerCase().includes(terms.toLowerCase()),
      );
      setFiltered(filtered);
    }

    if (terms.length < 3) {
      setFiltered(arrayData);
    }
  };

  const roles = [
    {
      id: 2,
      role: 'admin',
      description: 'Administrador',
    },
    {
      id: 3,
      role: 'gestor',
      description: 'Gestor',
    },
    {
      id: 4,
      role: 'corresp',
      description: 'Correspondente',
    },
    {
      id: 5,
      role: 'interno',
      description: 'Interno',
    },
    {
      id: 6,
      role: 'corretor',
      description: 'Corretor',
    },
    {
      id: 7,
      role: 'cliente',
      description: 'Cliente',
    },
  ];

  const button_on = (user) => {
    if (loading)
      return (
        <Spinner
          animation='border'
          variant='success'
          size='sm'
        />
      );
    if (user.ACTIVE)
      return (
        <Badge
          className='txt-200 bad-35'
          bg='success'
        >
          ON
        </Badge>
      );
    return (
      <Badge
        className='txt-200 bad-35'
        bg='dark'
      >
        OFF
      </Badge>
    );
  };

  return (
    <>
      <h4 className='hc-section-title'>Usuários</h4>
      <p className='mt-2 mb-5'>
        <span className='me-2 softxt'>Filtrar por</span>
        <input
          type='text'
          id='filter-users'
          className='filter-table'
          onChange={filter}
          pattern='.{3,20}'
        />
      </p>
      <table className='table table-striped table-hover f08 table-vertical-middle'>
        <thead>
          <tr>
            <th>Nome</th>
            <th>CPF</th>
            <th>Email</th>
            <th>Telefone</th>
            <th>Perfil</th>
            <th>Ativo</th>
          </tr>
        </thead>
        <tbody>
          {filtered.map((usr) => (
            <tr
              key={`tr_profile_${usr.CODIGO}`}
              className={usr.ACTIVE ? 'table-users' : 'table-users-off'}
            >
              <td>
                <span>
                  <IconsUI
                    info={ui.user.reg}
                    clsnm='m-1 fc-blue hov-green'
                    tooltip='Abrir cadastro do usuário'
                    click={() => navigate(`/user/${usr.CODIGO}`)}
                  />
                </span>
                {usr.NOME}
              </td>
              <td>{usr.CPF}</td>
              <td>{usr.EMAIL}</td>
              <td>{usr.TELEFONE}</td>
              <td>
                <Form.Select
                  aria-label='perfil'
                  size='sm'
                  onChange={async (event) => {
                    let codigo = usr.CODIGO;
                    let updated_role = event.target.value;
                    let role = roles.find(
                      (r) => r.description === updated_role,
                    );
                    try {
                      setLoading(true);
                      await post(user.update.role, {
                        codigo,
                        role: role.id,
                      });
                      await get_users();
                      setFiltered((prev) =>
                        prev.map((u) => {
                          if (u.codigo === usr.CODIGO) {
                            u.role_description = updated_role;
                            u.role = role.id;
                          }
                          return u;
                        }),
                      );
                      setLoading(false);
                    } catch (error) {
                      console.error(error);
                      setLoading(false);
                    }
                  }}
                >
                  {roles.map((role) => (
                    <option
                      key={`${role.id}_${usr.CODIGO}`}
                      value={role.description}
                      selected={role.description === usr.ROLE_DESCRIPTION}
                    >
                      {role.description}
                    </option>
                  ))}
                </Form.Select>
              </td>
              <td>
                <span
                  className='me-1 hov-green'
                  data-toggle='tooltip'
                  title={usr.ACTIVE ? 'Desativar' : 'Ativar'}
                  onClick={async () => {
                    let codigo = usr.CODIGO;
                    let updated_active = !usr.ACTIVE;

                    try {
                      setLoading(true);
                      await post(user.update.active, {
                        codigo,
                        active: updated_active,
                      });
                      await get_users();
                      setFiltered((prev) =>
                        prev.map((u) => {
                          if (u.codigo === usr.CODIGO) {
                            u.active = updated_active;
                          }
                          return u;
                        }),
                      );
                      // usr.ACTIVE = updated_active;
                    } catch (error) {
                      console.error(error);
                    }
                    setLoading(false);
                  }}
                >
                  {button_on(usr)}
                </span>
                {/* {user.active ? 'Sim' : 'Não'} */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default AdminUsers;
