import {
  FcApproval,
  FcBullish,
  FcCurrencyExchange,
  FcDepartment,
  FcDiploma2,
  FcEngineering,
  FcHome,
  FcLibrary,
  FcMindMap,
  FcMoneyTransfer,
  FcPrint,
  FcRules,
  FcSurvey,
} from 'react-icons/fc';

function EtapaIcon({ etapa, clsnm = '' }) {
  switch (etapa) {
    case 'simulação':
      return <FcBullish className={clsnm} />;
    case 'documentação do comprador':
      return <FcRules className={clsnm} />;
    case 'aprovação de crédito':
      return <FcMoneyTransfer className={clsnm} />;
    case 'documentação do vendedor':
      return <FcRules className={clsnm} />;
    case 'documentação do imóvel':
      return <FcHome className={clsnm} />;
    case 'avaliação de engenharia':
      return <FcEngineering className={clsnm} />;
    case 'confirmação de valores':
      return <FcCurrencyExchange className={clsnm} />;
    case 'assinatura de formulários':
      return <FcSurvey className={clsnm} />;
    case 'análise jurídica':
      return <FcLibrary className={clsnm} />;
    case 'assinatura do contrato':
      return <FcPrint className={clsnm} />;
    case 'prefeitura':
      return <FcDepartment className={clsnm} />;
    case 'cartório':
      return <FcDiploma2 className={clsnm} />;
    case 'finalizado':
      return <FcApproval className={clsnm} />;
    case 'mindmap':
      return <FcMindMap className={clsnm} />;
    default:
      return '';
  }
}

export default EtapaIcon;
