import React, { useContext, useEffect } from 'react';
import RenderSection from '../../components/RenderSection';
import RenderSectionButton from '../../components/RenderSectionButton';
import GlobalContext from '../../context/GlobalContext';

const System = () => {
  const { showSection, setShowSection } = useContext(GlobalContext);

  useEffect(() => {}, [showSection]);

  return (
    <div className='d-flex flex-column'>
      <div className='habita-col-1300 '>
        <div className='d-flex flex-row justify-content-between'>
          <RenderSectionButton
            section='simulacoes'
            classn='habita-card hide-xs'
            clk={() => setShowSection('simulacoes')}
          />
          <RenderSectionButton
            section='financiamentos'
            classn='habita-card hide-xs'
            clk={() => setShowSection('financiamentos')}
          />
          <RenderSectionButton
            section='tasks'
            classn='habita-card hide-xs'
            clk={() => setShowSection('tasks')}
          />
          <RenderSectionButton
            section='processos'
            classn='habita-card hide-xs'
            clk={() => setShowSection('processos')}
          />
          <RenderSectionButton
            section='clientes'
            classn='habita-card hide-xs'
            clk={() => setShowSection('clientes')}
          />
          <RenderSectionButton
            section='admin'
            classn='habita-card hide-xs'
            clk={() => setShowSection('admin')}
          />
        </div>
      </div>

      <div>
        <RenderSection section={showSection} />
      </div>
    </div>
  );
};

export default System;
