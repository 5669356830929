import { useContext } from 'react';
import { BiSolidMessageRoundedEdit } from 'react-icons/bi';
import { FaCircle, FaUser } from 'react-icons/fa6';
import { GoTasklist } from 'react-icons/go';
import { RiMenuAddLine } from 'react-icons/ri';
import { TbTimeline } from 'react-icons/tb';
import GlobalContext from '../context/GlobalContext';

function NewsTimelineItem({ item }) {
  const { a } = useContext(GlobalContext);
  const { prioridades } = a;

  const newsIcon = (item) => {
    switch (item.TABELA) {
      case 'ANDAMENTOS':
        return (
          <>
            <RiMenuAddLine className='svg12 me-2' />
            <span>
              Novo andamento cadastrado por {item.ANDAMENTO_USER_NOME} no{' '}
              <a
                href={`/processo/${item.PROCESSO_CODIGO}`}
                target='_blank'
                rel='noreferrer'
              >
                processo
              </a>{' '}
              de {item.NOME}
            </span>
          </>
        );
      case 'USERS':
        return (
          <>
            <FaUser className='svg10 me-2' />
            <span>Novo usuário: {item.NOME}</span>
          </>
        );
      case 'SIMULACOES':
        return (
          <>
            <FaUser className='svg12 me-2' />
            <span>Nova simulação recebida de {item.NOME}</span>
          </>
        );
      case 'TAREFA':
        return (
          <>
            <GoTasklist className='svg12 me-2' />
            {/* {item} */}
          </>
        );
      case 'messages':
        return (
          <>
            <BiSolidMessageRoundedEdit className='svg12 me-2' />
            <span>Nova mensagem de {item.NOME}</span>
            {/* {item} */}
          </>
        );
      case 'PROCESSOS':
        return (
          <>
            <TbTimeline className='svg12 me-2' />
            <span>
              Novo{' '}
              <a
                href={`/processo/${item.PROCESSO_CODIGO}`}
                target='_blank'
                rel='noreferrer'
              >
                processo
              </a>{' '}
              para {item.NOME}
            </span>
            {/* {item} */}
          </>
        );
      default:
        return '';
    }
  };

  const getColor = (prioridade_id) => {
    let color = prioridades.find((item) => item.ID === prioridade_id);
    return color ? color.ic : 'primary';
  };

  const criado = new Date(item.CREATED).toLocaleDateString();

  return (
    <li className='li-item'>
      <div className='li-content'>
        <FaCircle
          className={`svg05 me-3 svg-${getColor(item.PRIORIDADE_ID - 1)}`}
        />
        <span className='f07 me-2 fc-grey'>{criado}</span>
        <span className='card-subtitle f08'>{newsIcon(item)}</span>
      </div>
    </li>
  );
}

export default NewsTimelineItem;
