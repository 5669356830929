import PropTypes from 'prop-types';
import { useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import {
  create_link_whatsapp,
  curr,
  data_br,
  is_true,
} from '../assets/js/utils';
import Swal from 'sweetalert2';
import { sim } from '../services/endpoints';
import { post } from '../services/requests';

import IconsUI, { ui } from './IconsUI';

import ProcessoModal from './modals/ProcessoModal';
import SimulacaoModal from './modals/SimulacaoModal';

function SimulacaoLine({ obj, upd }) {
  const [showSimulacao, setShowSimulacao] = useState(false);
  const [showProcessoCreate, setShowProcessoCreate] = useState(false);
  const [active, setActive] = useState();
  const [loading, setLoading] = useState();

  const [modalSimulacaoFull, setModalSimulacaoFull] = useState(false);

  const handleCloseSimulacao = () => setShowSimulacao(false);
  const handleShowSimulacao = () => setShowSimulacao(true);

  const handleSetSimulacaoModalFull = () => {
    const newstate = !modalSimulacaoFull;
    setModalSimulacaoFull(newstate);
  };

  const handleCloseProcessoCreate = () => setShowProcessoCreate(false);
  const handleShowProcessoCreate = () => {
    setShowProcessoCreate(true);
  };

  const handleObj = (newObj) => {
    obj = newObj;
  };

  const get_older = (obj) => {
    if (obj.SIM_COMPLETA && obj.SIM_COMPOR) {
      const nascDate = new Date(obj.SIM_NASCIMENTO);
      const outroNascDate = new Date(obj.SIM_OUTRO_NASCIMENTO);
      const olderDate = nascDate < outroNascDate ? outroNascDate : nascDate;
      return data_br(olderDate);
    }
    return data_br(obj.SIM_NASCIMENTO);
  };

  function simpleDescription(description) {
    const simplifiedDescriptions = {
      'Aquisição de Imóvel Novo': 'Imóvel novo',
      Construção: 'Construção',
      'Reforma ou Ampliação': 'Reforma',
      'Aquisição de Imóvel Usado': 'Imóvel usado',
      'Aquisição de Terreno': 'Terreno',
      'Empréstimo Garantido por Imóvel': 'Empréstimo',
      'Imóveis CAIXA': 'Imóveis CAIXA',
    };

    return simplifiedDescriptions[description] || description; // Returns simplified description or the original if not found
  }

  obj.tipo = obj.SIM_COMPLETA ? 'Completa' : 'Simplificada';

  obj.imovel = {
    valor: parseFloat(obj.SIM_IMOVEL_VALOR),
    avaliacao: parseFloat(obj.SIM_IMOVEL_VALOR),
    cidade: obj.SIM_IMOVEL_CIDADE,
  };

  obj.perfil = {
    data_criado: new Date(obj.SIM_CREATED).toLocaleDateString('pt-BR'),
    hora_criado: new Date(obj.SIM_CREATED).toLocaleTimeString('pt-BR'),
    whatsapp: obj.SIM_WHATSAPP
      ? create_link_whatsapp(obj.SIM_WHATSAPP)
      : sim.USER_TELEFONE
      ? create_link_whatsapp(obj.USER_TELEFONE)
      : '',
    compor: obj.SIM_COMPOR,
    relacionamento: obj.SIM_RELACIONAMENTO,
    mais_velho: get_older(obj),
    cliente: {
      nome: obj.USER_NOME,
      cpf: obj.USER_CPF,
      nascimento: obj.SIM_NASCIMENTO,
      comprovacao: obj.SIM_COMPROVAR,
      renda: parseFloat(obj.SIM_RENDA),
      fgts: parseFloat(obj.SIM_FGTS),
      recursos: parseFloat(obj.SIM_RECURSOS),
    },
    outro: {
      nome: obj.SIM_OUTRO_NOME,
      cpf: obj.SIM_OUTRO_CPF,
      nascimento: obj.SIM_OUTRO_NASCIMENTO,
      comprovacao: obj.SIM_OUTRO_COMPROVAR,
      renda: parseFloat(obj.SIM_OUTRO_RENDA || 0),
      fgts: parseFloat(obj.SIM_OUTRO_FGTS || 0),
      recursos: parseFloat(obj.SIM_OUTRO_RECURSOS || 0),
    },
    beneficiado: is_true([obj.SIM_BENEFICIADO]),
    possui_imovel: is_true([obj.SIM_POSSUI_IMOVEL]),
    servidor_publico: is_true([obj.SIM_SERVIDOR_PUBLICO]),
    dependentes: is_true([obj.SIM_DEPENDENTES]),
    anos_fgts: is_true([obj.SIM_ANOS_FGTS]),
    clt: is_true([obj.SIM_CLT]),
    possui_imovel_cidade: obj.SIM_POSSUI_IMOVEL_CIDADE,
  };

  obj.resumo = {
    rendas: parseFloat(obj.perfil.cliente.renda + obj.perfil.outro.renda),
    fgts: parseFloat(obj.perfil.cliente.fgts + obj.perfil.outro.fgts),
    recursos: parseFloat(
      obj.perfil.cliente.recursos + obj.perfil.outro.recursos,
    ),
  };

  obj.resumo.entrada = parseFloat(obj.resumo.fgts + obj.resumo.recursos);
  obj.resumo.financiado = parseFloat(obj.imovel.valor - obj.resumo.entrada);

  obj.proc = {
    perfil: obj.perfil,
    imovel: obj.imovel,
    resumo: obj.resumo,
  };

  obj.proc.fin = {
    banco: '',
    tabela: '',
    taxa: '',
    linha: '',
    operacao: obj.SIM_IMOVEL_DESCRICAO,
    compartilhar: false,
    informacoes: obj.SIM_INFORMACOES,
    headline: '',
  };

  obj.proc.atendimento = {
    corretor: '',
    imobiliaria: '',
    correspondente: '',
  };

  obj.proc.user = {
    nome: obj.USER_NOME,
    cpf: obj.USER_CPF,
    whatsapp: obj.USER_TELEFONE,
    email: obj.USER_EMAIL,
  };

  const update_active = async (event) => {
    event.preventDefault();
    let codigo = obj.SIM_CODIGO;
    let updated_active = active ? 0 : 1;

    try {
      setLoading(true);
      await post(sim.update.active, {
        codigo,
        active: updated_active,
      });
      setActive(updated_active);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const delete_simulacao = async (event) => {
    event.preventDefault();
    const delete_sim = async () => {
      try {
        setLoading(true);
        await post(sim.delete, {
          codigo: obj.SIM_CODIGO,
        });
      } catch (error) {
        console.error(error);
      }
      upd();
      setLoading(false);
    };

    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você deseja deletar essa simulação? Essa ação não pode ser desfeita!',
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonColor: "#c41000",
      confirmButtonText: "Sim, deletar",	
      iconColor: "#c41000",
      denyButtonColor: "#6cbe00",
      denyButtonText: "Não",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Ok, deletado!", "", "success");
        delete_sim();
      } else if (result.isDenied) {
        Swal.fire("Tudo bem, vou manter a simulação aqui", "", "info");
      }

    });
  };

  const button_on = () => {
    if (loading)
      return (
        <Spinner
          animation='border'
          variant='success'
          size='sm'
        />
      );
    if (active == null) {
      setActive(obj.SIM_ACTIVE);
    }
    if (active)
      return (
        <Badge
          className='txt-200 bad-35'
          bg='success'
        >
          ON
        </Badge>
      );
    return (
      <Badge
        className='txt-200 bad-35 fc-black'
        bg='light'
      >
        OFF
      </Badge>
    );
  };

  const badgeCompor = () => {
    if (obj.SIM_COMPLETA && obj.SIM_COMPOR) {
      return (
        <Badge
          className=''
          pill
          bg='light'
          text='info'
        >
          <IconsUI
            info={ui.user.multi}
            clsnm='svg14'
            tooltip='Renda múltipla'
          />
        </Badge>
      );
    }
    return (
      <Badge
        className=''
        pill
        bg='light'
        text='primary'
      >
        <IconsUI
          info={ui.user.single}
          clsnm='svg14'
          tooltip='Renda única'
        />
      </Badge>
    );
  };

  const badgeFull = () => {
    if (obj.SIM_COMPLETA) {
      return (
        <Badge
          className=''
          pill
          bg='light'
          text='success'
          tooltip='Simulação Completa'
        >
          <IconsUI
            info={ui.letter.f}
            clsnm='svg14'
            tooltip='Simulação Completa'
          />
        </Badge>
      );
    }
    return (
      <Badge
        className=''
        pill
        bg='light'
        text='success'
      >
        <IconsUI
          info={ui.letter.s}
          clsnm='svg14'
          tooltip='Simulação Simplificada'
        />
      </Badge>
    );
  };

  return (
    <tr className='table-simulacoes'>
      <td>{obj.USER_NOME}</td>
      <td>
        <a href={obj.perfil.whatsapp}>
          <span className='hc-icon-modal mx-1'>
            <IconsUI
              info={ui.social.whatsapp}
              clsnm='svg09 fc-green'
            />
          </span>
          {obj.SIM_WHATSAPP}
        </a>
      </td>
      <td className='hide1000'>{obj.USER_EMAIL}</td>
      <td className='hide800'>{obj.SIM_IMOVEL_CIDADE}</td>
      <td>{simpleDescription(obj.SIM_IMOVEL_DESCRICAO)}</td>
      <td>{curr(obj.imovel.valor)}</td>
      <td className='hide1000'>{obj.perfil.data_criado}</td>
      <td>{badgeFull()}</td>
      <td>{badgeCompor()}</td>
      <td className='text-start tblnow'>
        <span
          className='me-1'
          data-toggle='tooltip'
          title={obj.SIM_ACTIVE ? 'Desativar' : 'Ativar'}
          onClick={update_active}
        >
          {button_on()}
        </span>
        <button
          type='button'
          className='btn btn-outline-light btn-sm me-1'
          data-toggle='tooltip'
          title='Abrir simulação'
          onClick={handleShowSimulacao}
        >
          <IconsUI
            info={ui.etapas.simulacao}
            clsnm='svg09 fc-blue'
          />
        </button>
        {obj.PROCESSO_CODIGO ? (
          <Link to={`/processo/${obj.PROCESSO_CODIGO}`}>
            <button
              type='button'
              className='btn btn-outline-light btn-sm me-1'
              data-toggle='tooltip'
              title='Abrir processo'
            >
              <IconsUI info={ui.folder.open} />
            </button>
          </Link>
        ) : (
          <>
            <button
              type='button'
              className='btn btn-outline-light btn-sm'
              data-toggle='tooltip'
              title='Criar novo processo'
              onClick={handleShowProcessoCreate}
            >
              <IconsUI info={ui.misc.add_database} />
            </button>
            <button
              type='button'
              className='btn btn-outline-light btn-sm ms-1'
              data-toggle='tooltip'
              title='Deletar simulação'
              onClick={delete_simulacao}
            >
              <IconsUI
                info={ui.action.delete.trash}
                clsnm='svg09 fc-red'
              />
            </button>
          </>
        )}
      </td>
      <>
        <Modal
          show={showSimulacao}
          size='lg'
          fullscreen={modalSimulacaoFull}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          onHide={handleCloseSimulacao}
        >
          <Modal.Header closeButton>
            <Modal.Title className='hc-modal-title'>
              <div className='d-flex flex-row justify-content-between'>
                <span>
                  Simulação<span className='txt-100 mx-2'>|</span>
                  <span className='hc-cli-title'>{obj.USER_NOME}</span>
                </span>
                <span className='ms-3 f09 fw500'>
                  {modalSimulacaoFull ? (
                    <Badge
                      className='txt-200 ms-1 btn-pointer'
                      bg='success'
                      onClick={handleSetSimulacaoModalFull}
                    >
                      Modo Fullscreen
                    </Badge>
                  ) : (
                    <Badge
                      className='txt-200 ms-1 btn-pointer fc-black'
                      bg='light'
                      onClick={handleSetSimulacaoModalFull}
                    >
                      Modo Janela
                    </Badge>
                  )}
                </span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SimulacaoModal
              obj={obj}
              on={active}
              changeActive={update_active}
              buttonON={button_on}
              load={loading}
              del={delete_simulacao}
              full={modalSimulacaoFull}
            />
          </Modal.Body>
          {/* <Modal.Footer>          
          </Modal.Footer> */}
        </Modal>
      </>
      <>
        <Modal
          show={showProcessoCreate}
          size='lg'
          // fullscreen={ true}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          onHide={handleCloseProcessoCreate}
        >
          <Modal.Header closeButton>
            <Modal.Title>Novo Processo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProcessoModal
              obj={obj}
              on={active}
              changeActive={update_active}
              buttonON={button_on}
              load={loading}
              nobj={handleObj}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='light'
              onClick={handleCloseProcessoCreate}
            >
              Sair
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </tr>
  );
}

SimulacaoLine.propTypes = {
  obj: PropTypes.object.isRequired,
  data: PropTypes.array,
};

export default SimulacaoLine;
