import React, { useContext } from 'react';
import GlobalContext from '../context/GlobalContext';
import IconsUI, { ui } from './IconsUI';

export default function NavReturn({ info = '' }) {
  const { navigate } = useContext(GlobalContext);
  return (
    <div className='d-flex flex-row justify-content-between fc-blue '>
      <h4 className='mt-2 mb-3'>{info}</h4>
      <p
        className='d-flex flex-row align-items-center hov-green'
        onClick={() => {
          navigate(-1);
        }}
      >
        <span className='me-2 softxt f08'>Voltar</span>
        <IconsUI
          info={ui.action.back}
          clsnm='hov-svg-soft'
        />
      </p>
    </div>
  );
}
