import Button from 'react-bootstrap/Button';
import { HiMiniAdjustmentsHorizontal } from 'react-icons/hi2';
import { links } from '../../config/conf';

export default function SimuleButton({ sm = false }) {
  if (sm)
    return (
      <a
        href={links.simule}
        target='_blank'
        rel='noreferrer'
        className='btn outline-secondary w-btn-hover'
      >
        <HiMiniAdjustmentsHorizontal />
      </a>
    );
  return (
    <a
      href={links.simule}
      target='_blank'
      rel='noreferrer'
    >
      <Button
        variant='primary'
        size='lg'
        className='w-btn-primary-simule'
      >
        <HiMiniAdjustmentsHorizontal />
        <span className='ms-2'>Faça uma simulação</span>
      </Button>
    </a>
  );
}
