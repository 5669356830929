let endpoint = window.location.hostname.includes('localhost')
  ? 'http://localhost:8000/habita'
  : 'https://core.bewook.com/habita';
// let endpoint = (window.location.hostname).includes('localhost') ? 'http://localhost:8000' : 'https://habitapi.bewook.com';

const ORACLE =
  'https://g3c32193a94f6bf-bwhabita.adb.sa-saopaulo-1.oraclecloudapps.com/ords/habita';

  endpoint = ORACLE;

let hostname = window.location.hostname.replace('www.', '');

const hostnameConfig = {
  'hamanncorp.com': {
    tenant: 'HamannCorp',
    brand: 'hamanncorp',
    headline: 'Financiamentos imobiliários de sucesso',
    site: 'hamanncorp.com',
    favicon: 'https://hamanncorp.com/assets/img/favicon.ico',
    setup: {
      name: 'Marcelo Hamann',
      whatsapp: '12997777374',
      email: 'credito@hamanncorp.com',
      title: 'HamannCorp',
      instagram: 'hamanncorp',
    },
  },
  'habitamaster.com': {
    tenant: 'HabitaMaster',
    brand: 'habitamaster',
    headline: 'Financiamentos imobiliários de sucesso',
    site: 'habitamaster.com',
    favicon: 'https://habitamaster.com/assets/img/favicon.ico',
    setup: {
      name: 'Marcelo Hamann',
      whatsapp: '12997777374',
      email: 'credito@habitamaster.com',
      title: 'HabitaMaster',
      instagram: 'habitamaster',
    },
  },
  'habitaprov.com': {
    tenant: 'Habitaprov',
    brand: 'habitaprov',
    headline: 'Transformando seu sonho em realidade',
    site: 'habitaprov.com',
    favicon: 'https://habitaprov.com/assets/img/favicon.ico',
    setup: {
      name: 'Marcelo Hamann',
      whatsapp: '12997777374',
      email: 'credito@habitaprov.com',
      title: 'Habitaprov',
      instagram: 'habitaprov',
    },
  },
  'api.telegram.org': {
    tenant: 'Habitaprov',
    brand: 'habitaprov',
    headline: 'Transformando seu sonho em realidade',
    site: 'habitaprov.com',
    favicon: 'https://habitaprov.com/assets/img/favicon.ico',
  },
  'localhost': {
    tenant: 'HamannCorp',
    brand: 'localhost',
    headline: 'Financiamentos imobiliários de sucesso',
    site: 'hamanncorp.com',
    favicon: 'https://hamanncorp.com/assets/img/favicon.ico',
    setup: {
      name: 'Marcelo Hamann',
      whatsapp: '12997777374',
      email: 'credito@hamanncorp.com',
      title: 'HamannCorp',
      instagram: 'hamanncorp',
    },
  },
  default: {
    tenant: 'HamannCorp',
    brand: 'hamanncorp',
    headline: 'Financiamentos imobiliários de sucesso',
    site: 'hamanncorp.com',
    favicon: 'https://hamanncorp.com/assets/img/favicon.ico',
    setup: {
      name: 'Marcelo Hamann',
      whatsapp: '12997777374',
      email: 'credito@hamanncorp.com',
      title: 'HamannCorp',
      instagram: 'hamanncorp',
    },
  },
};

const config = hostnameConfig[hostname] || hostnameConfig['default'];

const { tenant, brand, headline, site, favicon, setup } = config;

const images = {
  logo192: require(`../assets/img/brand/${brand}/logo192.png`),
  logo512: require(`../assets/img/brand/${brand}/logo512.png`),
  logo512neg: require(`../assets/img/brand/${brand}/logo512negativo.png`),
  logo1024: require(`../assets/img/brand/${brand}/logo1024.png`),
  logotext192: require(`../assets/img/brand/${brand}/logotext192.png`),
  logotext512: require(`../assets/img/brand/${brand}/logotext512.png`),
  logotext512neg: require(`../assets/img/brand/${brand}/logotext512negativo.png`),
  logotext1024: require(`../assets/img/brand/${brand}/logotext1024.png`),
};

export const links = {
  instagram: `https://www.instagram.com/${setup.instagram}`,
  mail: `mailto:${setup.email}`,
  whatsapp: `https://api.whatsapp.com/send?phone=55${setup.whatsapp}`,
  login: `/login`,
  register: `/register`,
  simule: `/simule`,
  simulador: `/simulador`,
};
const conf = {
  tenant: tenant,
  brand: brand,
  headline: headline,
  site: site,
  domain: window.location.hostname,
  endpoint: endpoint,
  slash: '/',
  images: images,
  favicon: favicon,
  ORA: ORACLE,
};

export { setup, config };
export { tenant, brand, headline, site, favicon, endpoint, ORACLE, images };

export default conf;
