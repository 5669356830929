import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaLock, FaRegEye } from 'react-icons/fa';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { IoEyeOffOutline } from 'react-icons/io5';
import GoogleCalendarLink from '../GoogleCalendarLink';

function AgendamentoModal({ procs, show, setShow, proc_etapa_id }) {
  const [notificar, setNotificar] = useState(false);
  const [visivel, setVisivel] = useState(false);

  const [nota, setNota] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [incluirCliente, setIncluirCliente] = useState(false);
  const [incluirCorretor, setIncluirCorretor] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [tipo, setTipo] = useState('');

  useEffect(() => {
    let start = new Date().toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
    });
    const parts = start.split(/[\s,]+/);
    const [day, month, year] = parts[0].split('/');
    const [hours, minutes] = parts[1].split(':');
    const formattedDatetimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
    setStartTime(formattedDatetimeString);
  }, []);

  const set_title = (tit) => {
    setTitle(`${tit} [${procs.CLIENTE_NOME}]`);
    setDescription(
      `${tit} [${procs.CLIENTE_NOME}]\n${procs.CLIENTE_TELEFONE}\nCorretor: ${procs.CORRETOR_NOME}\nImobiliária: ${procs.IMOBILIARIA_NOME}`,
    );
  };

  const duration = -120;

  return (
    <>
      <Modal
        show={show}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onHide={setShow}
      >
        <Modal.Header closeButton>
          <Modal.Title className='softxt'>Agendamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-row justify-content-end '>
            <OverlayTrigger
              placement='top'
              id={`tt_notificar_${proc_etapa_id}`}
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip>{notificar ? '' : 'Não '}Notificar Escritório</Tooltip>
              }
            >
              <span className={`ms-1 ${notificar ? 'c-green' : 'c-grey'}`}>
                <IoIosNotificationsOutline
                  onClick={() => setNotificar(!notificar)}
                />
              </span>
            </OverlayTrigger>

            <OverlayTrigger
              placement='top'
              id={`tt_visivel_${proc_etapa_id}`}
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip>{visivel ? '' : 'Não '}Visível ao Cliente</Tooltip>
              }
            >
              <span
                className={`ms-1 ${visivel ? 'c-green' : 'c-grey'}`}
                onClick={() => setVisivel(!visivel)}
              >
                {visivel ? <FaRegEye /> : <IoEyeOffOutline />}
              </span>
            </OverlayTrigger>
          </div>
          <div>
            <Form.Group
              className='mb-3'
              id={`new_agenda_${proc_etapa_id}`}
            >
              <div className='my-1 gocol500'>
                <Form.Check
                  defaultChecked={tipo === 'reuniao'}
                  className='me-3 f08'
                  type='radio'
                  name='tipo'
                  label={`Reunião`}
                  id={`set_tipo_reuniao_${proc_etapa_id}`}
                  onClick={() => {
                    setTipo('reuniao');
                    set_title('Reunião');
                  }}
                />

                <Form.Check
                  defaultChecked={tipo === 'contrato'}
                  className='me-3 f08'
                  type='radio'
                  name='tipo'
                  label={`Assinatura de Contrato`}
                  id={`set_tipo_contrato_${proc_etapa_id}`}
                  onClick={() => {
                    setTipo('contrato');
                    set_title('Assinatura de Contrato');
                  }}
                />

                <Form.Check
                  defaultChecked={tipo === 'forms'}
                  className='me-3 f08'
                  type='radio'
                  name='tipo'
                  label={`Assinatura de Formulários`}
                  id={`set_tipo_forms_${proc_etapa_id}`}
                  onClick={() => {
                    setTipo('forms');
                    set_title('Assinatura de Formulários');
                  }}
                />

                <Form.Check
                  defaultChecked={tipo === 'outros'}
                  className='me-3 f08'
                  type='radio'
                  name='tipo'
                  label={`Compromisso`}
                  id={`set_tipo_outro_${proc_etapa_id}`}
                  onClick={() => {
                    setTipo('outros');
                    set_title('Compromisso');
                  }}
                />
              </div>

              <div className='my-1'>
                <Form.Label>
                  <span className='f08'>Título</span>
                </Form.Label>
                <Form.Control
                  className='f08'
                  type='text'
                  value={title}
                  id={`new_agendamento_title_${proc_etapa_id}`}
                  onChange={(e) => set_title(e.target.value)}
                />
              </div>

              <div className='gocol500 justify-content-between my-1'>
                <div className='d-flex flex-column '>
                  <Form.Label>
                    <span className='f08'>Data e Horário</span>
                  </Form.Label>
                  <Form.Control
                    type='datetime-local'
                    className='f08'
                    size='sm'
                    value={startTime}
                    id={`new_agendamento_start_${proc_etapa_id}`}
                    onChange={(e) => {
                      setStartTime(e.target.value);
                      let newEnd = new Date(
                        new Date(e.target.value).toISOString(),
                      ).setMinutes(
                        new Date(e.target.value).getMinutes() + duration,
                      );
                      newEnd = new Date(newEnd).toISOString();
                      setEndTime(new Date(newEnd).toISOString().slice(0, 16));
                    }}
                  />
                </div>

                <div className='d-flex flex-column '>
                  <Form.Label>
                    <span className='f08'>Término</span>
                  </Form.Label>
                  <Form.Control
                    type='datetime-local'
                    className='f08'
                    size='sm'
                    value={endTime}
                    id={`new_agendamentoend_${proc_etapa_id}`}
                    onChange={(e) => {
                      setEndTime(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className='my-1'>
                <Form.Label>
                  <span className='f08'>Local</span>
                </Form.Label>
                <Form.Control
                  className='f08'
                  type='text'
                  value={location}
                  id={`new_agendamento_local_${proc_etapa_id}`}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>

              <div className='my-1'>
                <Form.Label>
                  <span className='f08'>Descrição</span>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows={5}
                  className='f08'
                  value={description}
                  id={`new_agendamento_desc_${proc_etapa_id}`}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className='my-2'>
                <Form.Label className='my-1 gocol500 align-items-center'>
                  <span className='my-1 f08 me-3'>Adicionar participantes</span>

                  <div className='mt-1 mb-0 gocol500  align-items-center'>
                    <Form.Check
                      defaultChecked={incluirCliente}
                      className='me-3 f08'
                      type='checkbox'
                      name='tipo'
                      label={`Cliente`}
                      id={`set_incluirCliente_${proc_etapa_id}`}
                      onClick={(e) => {
                        setIncluirCliente(e.target.checked);
                        let states = participants.slice();
                        if (e.target.checked) {
                          states.push(procs.CLIENTE_EMAIL);
                        } else {
                          states = states.filter(
                            (mail) => mail !== procs.CLIENTE_EMAIL,
                          );
                        }
                        setParticipants(states);
                      }}
                    />

                    <Form.Check
                      defaultChecked={incluirCorretor}
                      className='me-3 f08'
                      type='checkbox'
                      name='tipo'
                      label={`Corretor`}
                      id={`set_incluirCorretor_${proc_etapa_id}`}
                      onClick={(e) => {
                        setIncluirCorretor(e.target.checked);
                        let states = participants.slice();
                        if (e.target.checked) {
                          states.push(procs.CORRETOR_EMAIL);
                        } else {
                          states = states.filter(
                            (mail) => mail !== procs.CORRETOR_EMAIL,
                          );
                        }
                        setParticipants(states);
                      }}
                    />
                  </div>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows={1}
                  className='f08'
                  value={participants}
                  id={`new_agendamento_participantes_${proc_etapa_id}`}
                  onChange={(e) => {
                    const emails = e.target.value.split(',');
                    setParticipants(emails);
                  }}
                />
                <span className='mt-0 mb-2 f07 fc-grey'>
                  Adicione e-mails separados por vírgula
                </span>
              </div>
            </Form.Group>

            <Form.Group
              className='mb-3'
              id={`new_nota_${proc_etapa_id}`}
            >
              <Form.Label>
                <div>
                  <span className='me-1'>
                    <FaLock />
                  </span>
                  <span className='f08'>Nota Interna</span>
                </div>
              </Form.Label>
              <Form.Control
                as='textarea'
                className='f08'
                rows={2}
                value={nota}
                id={`new_nota_${proc_etapa_id}`}
                onChange={(e) => setNota(e.target.value)}
              />
            </Form.Group>
          </div>
          {/* {proc_etapa_id} */}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="light" onClick={setShow}>
            Sair
          </Button> */}
          <GoogleCalendarLink
            title={title}
            description={description}
            location={location}
            startTime={startTime.replace(/[-:]/g, '')}
            endTime={endTime.replace(/[-:]/g, '')}
            participants={participants}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AgendamentoModal;
