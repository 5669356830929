import { useContext, useEffect, useState } from 'react';
import { FcOpenedFolder } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import { curr } from '../../assets/js/utils';
import EtapaIcon from '../../components/EtapaIcon';
import Loading from '../../components/Loading';
import GlobalContext from '../../context/GlobalContext';
import { proc } from '../../services/endpoints';
import { post } from '../../services/requests';

function Processos() {
  const { checkLogin, logout } = useContext(GlobalContext);
  const [arrayProcessos, setArrayProcessos] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const get_processos = async () => {
      setLoading(true);
      try {
        const response = await post(proc.read.full, {});
        const data = response.data;
        setArrayProcessos(data);
        setFiltered(data);
      } catch (error) {
        console.log(error.response.data.message);
      }
      setLoading(false);
    };
    const status = checkLogin();
    if (status) {
      get_processos();
    } else {
      logout();
    }
  }, [checkLogin, logout]);

  if (loading)
    return (
      <>
        <Loading />
      </>
    );

  const filter_processos = () => {
    const terms = document.getElementById('filter-procs').value;

    if (terms.length > 2) {
      let filtered_array = arrayProcessos.filter(
        ({
          CIDADE_IMOVEL,
          OPERACAO,
          CORRESPONDENTE_NOME,
          CORRETOR_NOME,
          IMOBILIARIA_NOME,
          USER_TELEFONE,
        }) =>
          CIDADE_IMOVEL.toLowerCase().includes(terms.toLowerCase()) ||
          OPERACAO.toLowerCase().includes(terms.toLowerCase()) ||
          CORRESPONDENTE_NOME.toLowerCase().includes(terms.toLowerCase()) ||
          CORRETOR_NOME.toLowerCase().includes(terms.toLowerCase()) ||
          IMOBILIARIA_NOME.toLowerCase().includes(terms.toLowerCase()) ||
          USER_TELEFONE.toLowerCase().includes(terms.toLowerCase()),
      );
      setFiltered(filtered_array);
    }

    if (terms.length < 3) {
      setFiltered(arrayProcessos);
    }
  };

  if (arrayProcessos.length || filtered.length) {
    return (
      <>
        <h4 className='hc-section-title'>Processos</h4>
        <p className='mt-2 mb-5'>
          <span className='me-2 softxt'>Filtrar por</span>
          <input
            type='text'
            id='filter-processos'
            className='filter-table'
            onChange={filter_processos}
            pattern='.{3,20}'
          />
        </p>
        <table className='table table-striped table-hover'>
          <thead>
            <tr className='table-simulacoes hide600'>
              <th
                scope='col'
                className='softxt f08 txt-300'
              >
                Correspondente
              </th>
              <th
                scope='col'
                className='softxt f08 txt-300 hide800'
              >
                Corretor
              </th>
              <th
                scope='col'
                className='softxt f08 txt-300 hide1000'
              >
                Imobiliária
              </th>
              <th
                scope='col'
                className='softxt f08 txt-300 hide1000'
              >
                Cidade
              </th>
              <th
                scope='col'
                className='softxt f08 txt-300'
              >
                Operação
              </th>
              <th
                scope='col'
                className='softxt f08 txt-300'
              >
                Valor
              </th>
              <th
                scope='col'
                className='softxt f08 txt-300'
              >
                Cliente
              </th>
              <th
                scope='col'
                className='softxt f08 txt-300'
              >
                Telefone
              </th>
              <th
                scope='col'
                className='softxt f08 txt-300'
              >
                Etapa Atual
              </th>
              <th
                scope='col'
                className='softxt f08 txt-300'
              ></th>
            </tr>
          </thead>
          <tbody>
            {filtered &&
              filtered.map(
                ({
                  PROCESSO_CODIGO,
                  CORRESPONDENTE_NOME,
                  CORRETOR_NOME,
                  IMOBILIARIA_NOME,
                  CIDADE_IMOVEL,
                  OPERACAO,
                  VALOR_IMOVEL,
                  CLIENTE_NOME,
                  CLIENTE_TELEFONE,
                  ETAPA,
                }) => (
                  <tr
                    key={PROCESSO_CODIGO}
                    className='table-simulacoes'
                  >
                    <td>{CORRESPONDENTE_NOME}</td>
                    <td className='hide800'>{CORRETOR_NOME}</td>
                    <td className='hide1000'>{IMOBILIARIA_NOME}</td>
                    <td className='hide1000'>{CIDADE_IMOVEL}</td>
                    <td>{OPERACAO}</td>
                    <td>{curr(VALOR_IMOVEL)}</td>
                    <td>{CLIENTE_NOME}</td>
                    <td>{CLIENTE_TELEFONE}</td>
                    <td>
                      <EtapaIcon
                        etapa={ETAPA}
                        className='me-1'
                      />
                      {ETAPA}
                    </td>
                    <td>
                      <Link to={`/processo/${PROCESSO_CODIGO}`}>
                        <button
                          type='button'
                          className='btn btn-outline-light btn-sm'
                          data-toggle='tooltip'
                          title='Abrir processo'
                        >
                          <FcOpenedFolder />
                        </button>
                      </Link>
                    </td>
                  </tr>
                ),
              )}
          </tbody>
        </table>
      </>
    );
  } else {
    return null;
  }
}

export default Processos;
