import IconsUI from '../components/IconsUI';

const fileIcon = (type) => {
  if (type.includes('image'))
    return IconsUI({ info: ui.file.image, clsnm: 'svg09 fc-green' });
  if (type.includes('pdf'))
    return IconsUI({ info: ui.file.pdf, clsnm: 'svg09 fc-red' });
  return IconsUI({ info: ui.file.other, clsnm: 'svg09 fc-blur' });
};

const ui = {
  social: {
    whatsapp: 'whatsapp',
    email: 'email',
    calendar: {
      reg: 'calendar',
      add: 'calendar-add',
    },
    phone: {
      reg: 'phone',
      color: 'phone-color',
    },
    message: 'message',
  },
  action: {
    edit: 'edit',
    delete: {
      trash: 'delete-trash',
      circle: 'delete-circle',
    },
    add: {
      color: 'add-color',
      reg: 'add-reg',
      circle: 'add-circle',
    },
    expand: 'expand',
    download: 'download',
    upload: {
      color: 'upload-color',
      reg: 'upload-reg',
    },
    magnifier: 'magnifier',
    start: 'start',
    search: 'search',
  },
  folder: {
    open: 'open',
  },
  file: {
    pdf: 'pdf',
    image: 'image',
    other: 'other',
    attach: 'attach',
  },
  etapas: {
    simulacao: 'simulação',
    documentacao_comprador: 'documentação do comprador',
    aprovacao_credito: 'aprovação de crédito',
    documentacao_vendedor: 'documentação do vendedor',
    documentacao_imovel: 'documentação do imóvel',
    avaliacao_engenharia: 'avaliação de engenharia',
    confirmacao_valores: 'confirmação de valores',
    assinatura_formularios: 'assinatura de formulários',
    analise_juridica: 'análise jurídica',
    assinatura_contrato: 'assinatura do contrato',
    prefeitura: 'prefeitura',
    cartorio: 'cartório',
    finalizado: 'finalizado',
    mindmap: 'mindmap',
  },
  status: {
    waiting: 'waiting',
    done: 'done',
  },

  user: {
    reg: 'user',
    add: 'user-add',
    delete: 'user-delete',
  },
  misc: {
    cross: 'cross',
    check: {
      color: 'check',
      circle: 'check-circle',
    },
    clock: 'clock',
    edit: 'edit',
    add_database: 'add-database',
    tasks: 'tasks',
    star: 'star',
    house: 'house',
    money: 'money',
  },
};

export { fileIcon, ui };
