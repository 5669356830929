import { TfiEmail } from 'react-icons/tfi';
import { links } from '../../config/conf';

export default function EmailButton() {
  return (
    <a
      href={links.mail}
      target='_blank'
      rel='noreferrer'
      className='btn outline-secondary w-btn-hover'
    >
      <TfiEmail />
    </a>
  );
}
